import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Button from 'common/components/elements/buttons/primary';
import ChatOptions from '../option';
import PropTypes from 'prop-types';
import TruncateText from 'common/components/elements/truncate-text';
import VoiceMessage from '../voice-message';
import { audioRecorder, computeAudioDuration, requestPermissionAndGetDevices, saveDevice, getDevice, removeDevice } from 'common/utils/audioRecording';
import CameraAccessDialogs from 'common/components/modules/webcam/camera-access-dialogs-v2';
import MicSettingsModal from '../mic-settings';
import { getAllowedVideoExtentions, getBrowserName, isMobileAndTablet } from 'common/utils/utils';

import toast from 'common/utils/toast';
import MessageFieldEmojisPicker from '../message-field-emojis-picker';
import Icon from 'common/components/elements/icons';
import DiscardChangeMethodModal from 'admin/views/media-modules/discard-change';
const notSupportedBrosersName = ['firefox', 'safari'];
const MAX_VOICE_MESSAGE_LIMIT = 600;

function TextMessageField({
   replyMessage,
   canelReplyMessage,
   mutedInfo,
   inputRef,
   onReadMessage,
   isMobile,
   inputForIosFocus,
   setMutedMemberInfo,
   isConnected,
   isMessageToLong,
   setErrorToLong,
   isShowUser,
   userAvatar,
   isAdmin,
   isLivstream,
   onUserTyping,
   onSelectLibery,
   prevFiles,
   sendMessage,
   onUploadFile,
   showContent,
   sendVoiceMessage,
   privateAttachmentSettings,
   isGroup,
   defaultVoicePrice,
   intoNotificationCard,
   onUpdateRecordStatus,
   children,
   containerRef,
   isLoadingSendBtn,
   setIsLoadingSendBtn,
   isUpdateState,
   onClickCancelEditMessage,
   setDisabledSendTo,
   disabledSendTo,
   showNickname,
   isMiniChat,
   hideVoiceMessage,
   isMassMessage,
   onCustomAction,
   showVariables,
   variableList,
   showFrequentlyUsedEmojis,
   isDemoMode,
   authUser,
   onClearState,
   disableAttachments,
   updateMessageType,
   isMaxAttachmentCountReached,
   saveAsDraftTextMessage,
   onClickSavedReplies,
   isSavedReplies,
   onInputBlur,
   idForField,
   textLimit,
   calcTextLengthFor,
   setReplyData,
   repliesModalState,
   replyData,
   setMessageType,
   errors,
   onInputFocus,
}) {
   // const [text, setMessageText] = useState('');
   const [cursorPosition, setCursorPosition] = useState(null);
   const [showEmojis, setShowEmojis] = useState(false);

   // const [disabledSendTo, setDisabledSendTo] = useState(true);
   const [openVoiceMessage, setOpenVoiceMessage] = useState(false);
   const [audioDuration, setAudioDuration] = useState(0);
   const [audioAsblob, setAudioAsblob] = useState(null);
   const [audioSrc, setAudoSrc] = useState(null);
   const [cameraAccessDialogData, setCameraAccessDialogData] = useState({ isOpen: false, type: null });
   const [micSettingsModal, setMicSettingsModal] = useState({ isOpen: false, devices: [], error: [], selectedDeviceId: null });
   const [tempEditableMessage, setTempEditableMessage] = useState('')
   const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false)
   const [dragActive, setDragActive] = useState(false);


   const voiceTimeTimer = useRef(null);

   useEffect(() => {
      if(!!inputRef?.current && isUpdateState) {
         const text = inputRef.current.innerText.trim();
         setTempEditableMessage(text)
         setDisabledSendTo(true)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputRef, isUpdateState])

   useEffect(() => {
      const handleClickOutside = (event) => {
         const elm = document.querySelector('#emoji-mart')
         const pickerElm = document.querySelector('.chat-emoji-picker')
         if((elm && !elm.contains(event.target)) && (pickerElm && !pickerElm.contains(event.target))){
            setShowEmojis(false)
         }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         clearInterval(voiceTimeTimer.current);
         voiceTimeTimer.current = null
         document.removeEventListener('mousedown', handleClickOutside);
      }
   }, []);

   useEffect(() => {
      if(replyData) {
         if(replyData?.voice_message_data) {
            console.log('replyData?.voice_message_data', replyData?.voice_message_data);

            if(!!(replyData?.voice_message_data instanceof Blob)) {
               let blob = replyData?.voice_message_data

               let readerr = new FileReader();
               readerr.readAsDataURL(blob);

               // clearInterval(voiceTimeTimer.current);
               // voiceTimeTimer.current = null

               readerr.onloadend = (e) => {
                  console.log('e.target.resultttt');

                  setAudoSrc(e.target.result)
                  setAudioDuration(replyData?.voice_message_duration)
                  setOpenVoiceMessage(true)
               };
            }
            if(replyData?.voice_message_data_src && typeof replyData?.voice_message_data_src === 'string' && replyData?.voice_message_data_src.includes('audio-')) {
               setAudoSrc(replyData?.voice_message_data_src)
               setAudioDuration(replyData?.voice_message_duration)
               setOpenVoiceMessage(true)

            }



            // setAudoSrc(blob)
            // let downloadUrl = window.URL.createObjectURL(blob);
            // window.open(downloadUrl)
            // const audio = new Audio(downloadUrl)
            // console.log({ audio });
            // audio.load()
            // audio.play()

         }
      }

      return () => {
         if(isSavedReplies) {
            setMessageType(null)
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


   const onSendMessage = (e) => {

      // console.log('onSendMessage', { e, 'e.target': e.target, 'calcTextLengthFor(e.target?.innerText?.trim())': calcTextLengthFor(e.target?.innerText?.trim()) });

      if(isMassMessage) return
      if(e?.keyCode === 13 && !e.shiftKey) {
         // setIsLoadingSendBtn(true)
      }
      if(isSavedReplies) {
         if(!!onCustomAction) {
            onCustomAction('text_length', calcTextLengthFor(e?.target?.innerText?.trim()), inputRef.current?.innerText?.trim())
         }
         if(calcTextLengthFor(e.target.innerText?.trim()) > textLimit) {
            // inputRef.current.innerText = inputRef.current.innerText?.trim().slice(0, textLimit - 1)

            // inputRef.current.blur()
            // inputRef.current.innerText = 44444
         }
         if(!([46, 8].includes(e.keyCode))) {
            return
         }
      }
      sendMessage(e, () => {
         setDisabledSendTo(true);
      })
      setShowEmojis(false);

      // if(/Android/i.test(navigator.userAgent)) {
      //    ChatHepler.inputState()
      // }

      if(isMobile) {
         // alert('inputRef.current.innerText', inputRef.current.innerText)
         // alert('isAndroid', /Android/i.test(navigator.userAgent))
         inputRef?.current?.focus()
      }
   }

   const getCurrentPosition = () => {
      const editableDiv = inputRef.current
      var positionCursor = 0,
         sel, range;
      if(window.getSelection) {
         sel = window.getSelection();
         if(sel.rangeCount) {
            range = sel.getRangeAt(0);
            if(range.commonAncestorContainer.parentNode === editableDiv) {
               positionCursor = range.endOffset;
            }
         }
      } else if(document.selection && document.selection.createRange) {
         range = document.selection.createRange();
         if(range.parentElement() === editableDiv) {
            var tempEl = document.createElement("span");
            editableDiv.insertBefore(tempEl, editableDiv.firstChild);
            var tempRange = range.duplicate();
            tempRange.moveToElementText(tempEl);
            tempRange.setEndPoint("EndToEnd", range);
            positionCursor = tempRange.text.length;
         }
      }
      return positionCursor
   }
   const switchShowEmojis = async () => {
      await setShowEmojis(!showEmojis);
      // Modern browsers
      const ctrl = inputRef.current;
      if(ctrl.setSelectionRange) {
         ctrl.focus();
         ctrl.setSelectionRange(cursorPosition, cursorPosition);

      // IE8 and below
      } else if(ctrl.createTextRange) {
         const range = ctrl.createTextRange();
         range.collapse(true);
         range.moveEnd('character', cursorPosition);
         range.moveStart('character', cursorPosition);
         range.select();
      }
   }

   const addEmoji = (e) => {
      const sym = e.unified.split('-');
      const codesArray = [];
      sym.forEach(el => codesArray.push(`0x${ el }`));
      const emojiPic = String.fromCodePoint(...codesArray);
      const el = inputRef.current;

      const selection = window.getSelection();
      const range = document.createRange();
      selection.removeAllRanges();
      range.selectNodeContents(el);
      range.collapse(false);
      selection.addRange(range);
      el.focus();

      if(isMassMessage) {
         onCustomAction('body', selection?.anchorNode?.textContent.concat(emojiPic))
      }

      window.document.execCommand('insertText', false, emojiPic);
   }

   const onBlur = () => {
      const positionCursor = getCurrentPosition()
      setCursorPosition(positionCursor);
      inputForIosFocus(false)
      if(onInputBlur) {
         onInputBlur(positionCursor)
      }
   }

   const onCancelVoiceMessage = (isDelete = false, callBack) => {
      if(!isDelete && audioRecorder.mediaRecorder){
         audioRecorder.cancel();
         clearInterval(voiceTimeTimer.current);
         voiceTimeTimer.current = null
      }
      if(callBack){
         callBack();
      }
      onUpdateRecordStatus(null)
      setOpenVoiceMessage(false);
      setAudioAsblob(null);
      setAudoSrc(null);
   }
   const onStopVoiceMessage = (duration) => {
      console.log('onStopVoiceMessage---');

      audioRecorder.stop()
         .then(audioAsblob => {
            onUpdateRecordStatus('stop')
            setAudioAsblob(audioAsblob);
            const recorderAudioAsBlob = audioAsblob;
            let reader = new FileReader();
            clearInterval(voiceTimeTimer.current);
            voiceTimeTimer.current = null
            reader.onload = (e) => {
               console.log('e.target.result');

               setAudoSrc(e.target.result)

               if(setReplyData) {
                  setReplyData({
                     voice_message_data: audioAsblob,
                     voice_message_duration: audioDuration,
                  }, repliesModalState)
               }
            };
            reader.readAsDataURL(recorderAudioAsBlob);

         })
         .catch(error => {
         //Error handling structure
            console.log(error.name, 'onStopVoiceMessage')
            switch (error.name) {
               case 'InvalidStateError': //error from the MediaRecorder.stop
                  console.log("An InvalidStateError has occured.");
                  break;
               default:
                  console.log("An error occured with the error name " + error.name, error);
            }
         });

   }

   const handleDeviceChange = (data, action) => {
      setMicSettingsModal({  ...micSettingsModal, isOpen: false, selectedDeviceId: data.id, selectedDeviceLable: data.lable });
      saveDevice(data.id);
      switch (action) {
         case 'stop':

            break;

         case 'start':
            startAudioRecorder();
            break;

         default:
            break;
      }
   }
   const isNotSupported = () => {
      const browserName = getBrowserName();
      const isNotSupported = isMobileAndTablet() || notSupportedBrosersName.includes(browserName);
      return isNotSupported
   }


   const checkOpenMicSettings = () => {
      let savedDeviceId = getDevice('audio');

      return !savedDeviceId && !isNotSupported();
   }

   const onCloseMicSettingsModal = () => {
      removeDevice()
      setMicSettingsModal({  ...micSettingsModal, isOpen: false, selectedDeviceId: null });
   }
   const  handleDevicePerrmitionError = (error) => {
      //No Browser Support Error
      console.log(error, 'handleDevicePerrmitionError')

      if(error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
         console.log("To record audio, use browsers like Chrome and Firefox.");
      }

      //Error handling structure
      switch (error.name) {
         case 'AbortError': //error from navigator.mediaDevices.getUserMedia
            console.log("An AbortError has occured.");
            break;
         case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
            setCameraAccessDialogData({ isOpen: true, type: 'block' })
            console.log("A NotAllowedError has occured. User might have denied permission.");
            break;
         case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
            setCameraAccessDialogData({ isOpen: true, type: 'not_found' })
            console.log("A NotFoundError has occured.");
            break;
         case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
            console.log("A NotReadableError has occured.");
            break;
         case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
            console.log("A SecurityError has occured.");
            break;
         case 'TypeError': //error from navigator.mediaDevices.getUserMedia
            console.log("A TypeError has occured.");
            break;
         case 'InvalidStateError': //error from the MediaRecorder.start
            console.log("An InvalidStateError has occured.");
            break;
         case 'UnknownError': //error from the MediaRecorder.start
            console.log("An UnknownError has occured.");
            break;
         default:
            console.log("An error occured with the error name " + error.name);
      }
   }

   const initDevice = async (callBack, isOpenSettings = true) => {
      const { devices, error } = await requestPermissionAndGetDevices()

      if(!error) {
         const { selectedDeviceId } = micSettingsModal
         console.log({ selectedDeviceId });

         let audioDeviceId = null;
         if(selectedDeviceId) {
            audioDeviceId = selectedDeviceId;
            const exists = devices.find(device => device.deviceId === selectedDeviceId);
            if(!exists){
               audioDeviceId = devices[0].deviceId;
            }
         } else {
            let savedDeviceId = getDevice('audio');
            if(savedDeviceId) {
               const exists = devices.find(device => device.deviceId === savedDeviceId);
               if(!exists) {
                  savedDeviceId = null;
                  removeDevice();
               }
            }
            audioDeviceId = savedDeviceId || devices[0].deviceId;
         }
         const selectedDeviceLable = devices.find(el => el.deviceId === audioDeviceId)?.label;
         let useCallBack = true;

         if(!isOpenSettings){
            console.log(1);
            if(!devices.find(device => device.deviceId === selectedDeviceId)){
               useCallBack = false
               setMicSettingsModal({ selectedDeviceId: audioDeviceId, selectedDeviceLable, devices, isOpen: true });
            }
         } else {
            setMicSettingsModal({ selectedDeviceId: audioDeviceId, selectedDeviceLable, devices, isOpen: true });
         }
         if(callBack && useCallBack){
            callBack(audioDeviceId)
         }
      } else {
         handleDevicePerrmitionError(error);
      }
   }

   const startAudioRecorder = async () => {
      const activeDevice = getDevice() || null;
      setCameraAccessDialogData({ isOpen: false, type: null })
      audioRecorder.start(activeDevice)
         .then(() => {
            onUpdateRecordStatus('start')
            const  audioRecordStartTime = new Date();
            setOpenVoiceMessage(true);
            setDisabledSendTo(false)
            if(voiceTimeTimer.current) {
               clearInterval(voiceTimeTimer.current);
               voiceTimeTimer.current = null
            }
            voiceTimeTimer.current = setInterval(() => {
               let audioDuration = computeAudioDuration(audioRecordStartTime);
               displayaudioDurationDuringAudioRecording(audioDuration);
               setAudioDuration(audioDuration);
            }, 100); //ev
         })
         .catch(error => {
            handleDevicePerrmitionError(error);
         });
   }

   const onSendVoiceMessage = details => {
      console.log('onSendVoiceMessage', { details });

      setIsLoadingSendBtn(true)
      sendVoiceMessage(audioAsblob, { ...details, duration: audioDuration, replyMessage }, () => {
         setOpenVoiceMessage(false)
         if(replyMessage?.parentMessageId){
            canelReplyMessage()
         }
         if(!isMobile &&  inputRef.current) {
            inputRef.current.focus()
         }
         setIsLoadingSendBtn(false)
         setDisabledSendTo(true);
      }, replyMessage)
   }
   const leavChatConversationDispatchEvent = (payload) => {
      const leavChatConversation = new CustomEvent('leav-chat-conversation', {
         detail: {
            payload,
         },
      })
      window.dispatchEvent(leavChatConversation);
   }
   const displayaudioDurationDuringAudioRecording = (audioDuration) =>  {
      let limit = MAX_VOICE_MESSAGE_LIMIT;
      if(!isAdmin && privateAttachmentSettings?.private_max_voice_message_duration_limit) limit = privateAttachmentSettings.private_max_voice_message_duration_limit;
      if(audioDuration > limit) {
         toast.error(`${ limit } seconds voice note time limit has been reached`)
         leavChatConversationDispatchEvent({ audio_recorded_status: 'limit_reached' })
      }
   }

   const getOptionsData = () => {
      let optionsData = null;
      let modifiedOptions = []
      const uploadOptions = [
         { title: 'Attach image', iconName: 'image-outline', isUpload: true, type: 'photo_unlock', resourceType: 'photo_vault' },
         { title: 'Attach video', iconName: 'video-camera', isUpload: true, type: 'video_unlock', resourceType: 'video_vault' },
      ]
      const otherOptions = [
         { title: 'Add voice message', iconName: 'mic', type: 'voice', resourceType: 'voice', className: 'intercom-chat-voice-message', tooltipText: 'Add voice message', isDisabled: isLoadingSendBtn || disableAttachments || isUpdateState || isDemoMode },
         { title: 'Select from media', iconName: 'attach-media', type: 'photo_unlock', resourceType: 'photoset', tooltipText: 'Select from media', isDisabled: isLoadingSendBtn || disableAttachments || isUpdateState },
      ]

      modifiedOptions = [
         {
            iconName: 'attach',
            groupType: 'attach',
            tooltipText: isDemoMode ? 'Not available in demo' : isMaxAttachmentCountReached ? 'Limit of 20 attachments has been reached' : 'Attach',
            options: uploadOptions,
            isDisabled: isLoadingSendBtn || isDemoMode || disableAttachments || isUpdateState || isMaxAttachmentCountReached,
         },
         ...otherOptions,
      ]

      if(isAdmin){
         optionsData = [...modifiedOptions]

         if(!isMassMessage && !isSavedReplies && !isGroup) {
            optionsData = [
               ...optionsData,
               { title: 'Saved replies', iconName: 'saved-replies', type: 'saved_replies', resourceType: 'saved_replies', tooltipText: 'Saved replies', isDisabled: disableAttachments || isUpdateState },
            ]

         }

      } else if(!isGroup && (privateAttachmentSettings?.voice_message_is_active || privateAttachmentSettings?.photo_message_is_active)){
         optionsData = [];
         if(privateAttachmentSettings.photo_message_is_active){
            optionsData = [
               { title: 'Attach image', iconName: 'attach', isUpload: true, type: 'photo_unlock', resourceType: 'photo_vault' },
            ]
         }
         if(privateAttachmentSettings.voice_message_is_active){
            optionsData = [
               ...optionsData,
               { title: 'Add voice message', iconName: 'mic', type: 'voice', resourceType: 'voice', tooltipText: isDemoMode ? 'Not available in demo' : 'Add voice message', isDisabled: isDemoMode },
            ]
         }
      }

      if(hideVoiceMessage) {
         modifiedOptions = modifiedOptions.filter(it => it.type !== 'voice')
         optionsData = optionsData.filter(it => it.type !== 'voice')
      }

      if(isLivstream) return null

      return optionsData
   }

   const getMicSettingsState = () => {
      return {
         devices: micSettingsModal?.devices || [],
         error: micSettingsModal?.error || [],
         activeDeviceId: micSettingsModal?.selectedDeviceId,
         activeDeviceLable: micSettingsModal?.selectedDeviceLable,
         onCloseModal: onCloseMicSettingsModal,
      }
   }

   const onChangeChatInputHeight = () => {
      if(!!containerRef) {
         let children = Array.from(containerRef.current.children)
         let chatWrapperFiledScrollHeight = children[children?.length - 1]?.scrollHeight
         let chatInputrFiledHeight = inputRef?.current?.offsetHeight
         let chatInputWrapperMaxHeight = containerRef.current.clientHeight * (window.innerHeight > 800 ? 0.65 : 0.75)
         let wrapperHeightWithoutInput = chatWrapperFiledScrollHeight - chatInputrFiledHeight
         children[children?.length - 1].style.maxHeight = chatInputWrapperMaxHeight + 'px'

         if(inputRef.current) inputRef.current.style.maxHeight = chatInputWrapperMaxHeight - wrapperHeightWithoutInput - 15 + 'px'
      }
   }

   const initDevices = () => {
      const isOpenSettings = checkOpenMicSettings();
      if(isOpenSettings){
         console.log('isOpenSettings');

         initDevice();
      } else {
         initDevice(() => {
            console.log('!isOpenSettings');
            startAudioRecorder()
         }, false);
      }
   }

   useEffect(() => {
      onChangeChatInputHeight()
   })

   const handleDrag = (e) => {
      if(isDemoMode) return
      e.preventDefault();
      e.stopPropagation();
      if(e.type === "dragenter" || e.type === "dragover") {
         setDragActive(true);
      } else if(e.type === "dragleave") {
         setDragActive(false);
      }
      setDragActive(dragActive)
   };

   const handleDrop = (e) => {
      if(isDemoMode) return
      e.preventDefault();
      e.stopPropagation();


      setDragActive(false);
      if(e.dataTransfer.files && e.dataTransfer.files[0]) {
         e.dataTransfer.effectAllowed = "all";
         e.dataTransfer.dropEffect = "move"

         try {
            const validImageFormats = 'image/png, image/jpeg, image/jpg, image'
            const validVideoFormats = getAllowedVideoExtentions()?.join(',')

            const dataTransfer = new DataTransfer()

            Array.from(e.dataTransfer.files).forEach(file => {
               if(validImageFormats.includes(file.type)) {
                  dataTransfer.items.add(file)
                  e.target.files = dataTransfer.files;
                  onUploadFile(e, 'photo_unlock')
               } else if(validVideoFormats.includes(file.type)) {
                  dataTransfer.items.add(file)
                  e.target.files = dataTransfer.files;
                  onUploadFile(e, 'upload_video')
               }
            });
         } catch (error) {
            console.log(error);
         }

      }
   };


   if(openVoiceMessage){
      return (
         <>
            {
               micSettingsModal?.isOpen && (
                  <MicSettingsModal
                     { ...getMicSettingsState() }
                     onSaveDevice={ (data) => handleDeviceChange(data, 'stop') }
                  />
               )
            }
            <VoiceMessage
               status='progress'//'complited'
               isMobile={ isMobile }
               audioRecorder={ audioRecorder }
               onCancel={ onCancelVoiceMessage }
               onStop={ onStopVoiceMessage }
               duration={ audioDuration }
               audioAsblob={ audioAsblob }
               audioSrc={ audioSrc }
               onSend={ onSendVoiceMessage }
               disabledSendTo={ disabledSendTo }
               isAdmin={ isAdmin }
               withoutPricingContnet={ isGroup || !isAdmin }
               defaultVoicePrice={ defaultVoicePrice }
               onOpenMicSettings={ initDevice }
               withoutMicSettings={ isNotSupported() }
               isLoadingSendBtn={ isLoadingSendBtn }
               replyMessage={ replyMessage }
               showNickname={ showNickname }
               canelReplyMessage={ canelReplyMessage }
               authUser={ authUser }
               setReplyData={ setReplyData }
               isSavedReplies={ isSavedReplies }
               repliesModalState={ repliesModalState }
               replyData={ replyData }
               onUpdateRecordStatus={ onUpdateRecordStatus }
               setMessageType={ setMessageType }
            />

         </>
      )
   }

   return (
      <>
         {
            micSettingsModal?.isOpen && (
               <MicSettingsModal
                  { ...getMicSettingsState() }
                  onSaveDevice={ (data) => handleDeviceChange(data, 'start') }
               />
            )
         }
         {
            cameraAccessDialogData.isOpen && (
               <CameraAccessDialogs
                  deviceName='microphone'
                  onClose={ () => setCameraAccessDialogData({
                     isOpen: false,
                     type: 'allow',
                  }) }
                  type={ cameraAccessDialogData.type }
               />
            )
         }
         <div
            role='presentation'
            className={ cx({
               'chat-message-field mt-auto relative': true,
               'chat-message-field-disabled': !isConnected,
            }) }
         >
            <div
               className={ cx({
                  'flex flex-col h-full bg-panel dark:bg-panel-dark': !isMassMessage,
                  'flex flex-col h-full bg-panel dark:bg-panel-dark rounded-lg': isMassMessage,
                  '!p-4': !isMassMessage && !isSavedReplies,
               }) }
               id={ isMassMessage ? `private_message_field` : 'chat-input-field' }
               onDragEnter={ handleDrag } onDragLeave={ handleDrag } onDragOver={ handleDrag } onDrop={ handleDrop }
            >
               <div
                  className={ cx({
                     'z-20 flex flex-col border border-divider dark:border-divider-dark rounded-lg': true,
                     'right-0': prevFiles.length,
                     'w-full': !prevFiles.length,
                     'pb-3': !isMobile && showFrequentlyUsedEmojis,
                     'p-3': !(!isMobile && showFrequentlyUsedEmojis),
                     'border-error dark:border-error-dark': errors,
                  }) }
                  id='chat-input-wrapper'
               >
                  {
                     !isMobile && showFrequentlyUsedEmojis && (
                        <MessageFieldEmojisPicker
                           showEmojis={ showEmojis }
                           switchShowEmojis={ switchShowEmojis }
                           addEmoji={ addEmoji }
                           topPosition={ isSavedReplies ? '0px' : '' }
                           // topPosition={ isSavedReplies ? '-37.5px' : '' }
                        />
                     )
                  }
                  <div
                     className={ cx({
                        'flex flex-col overflow-hidden flex-1 justify-around dark:text-major-dark w-full': true,
                        'border-error dark:border-error-dark': isMessageToLong,
                        'dark:border-divider-dark border-divider': !isMessageToLong,
                        'px-3.5': !isMobile && showFrequentlyUsedEmojis,
                        '!overflow-y-auto **ams-custom-scrool custom-scrollbar !max-h-[160px]': isSavedReplies,
                     }) }>
                     {
                        replyMessage &&  replyMessage.parentMessageId &&
                        <div
                           className={ cx({
                              'flex flex-col gap-1.5 pb-3': true,
                              'pt-3': !showFrequentlyUsedEmojis && isAdmin,
                              'reply-newmessage': !intoNotificationCard,
                           }) }
                        >
                           <div className='flex gap-2 items-center'>
                              <p className='text-sm font-normal leading-[18px] text-secondary dark:text-secondary-dark flex gap-1'>
                                 Replying to
                                 <TruncateText
                                    className='flex text-secondary dark:text-secondary-dark'
                                    text={ isAdmin || showNickname ? replyMessage?.user?.nickname ||  replyMessage?.user?.username || 'your message' : replyMessage?.user?.uuid === authUser?.uuid ? 'your message' : replyMessage.user.username }
                                    textClass='text-xm font-semibold'
                                    textSize='14px'
                                    fontWeight='600'
                                    width='200px'
                                 />
                              </p>
                              <Icon
                                 name='cancel'
                                 onClick={ canelReplyMessage }
                                 color='secondary'
                                 className='cursor-pointer'
                              />
                           </div>
                           {
                              !['text_message', 'mass_message'].includes(replyMessage.type) && !!replyMessage.type && (
                                 <div className='flex max-w-250 text-secondary dark:text-secondary-dark items-start'>
                                    <span className='text-xs '>{replyMessage.type === 'voice_message' ? 'Voice message' : 'Content'}!</span>
                                 </div>
                              )
                           }
                           {
                              replyMessage.text && 'voice_message' !== replyMessage.type  && (
                                 <div className='max-w-[307px] w-fit bg-placeholder-12 dark:bg-placeholder-12 py-0.5 px-1 rounded-lg'>
                                    <TruncateText
                                       className='flex text-secondary dark:text-secondary-dark !h-4'
                                       text={ ['mass_message'].includes(replyMessage.type) ? replyMessage?.text?.subject : replyMessage?.text }
                                       textClass='text-xs !leading-4'
                                       textSize='12px'
                                       fontWeight='400'
                                       width='fit-content'
                                    />
                                 </div>
                              )
                           }
                        </div>
                     }
                     <div
                        className='ams-custom-scrool dont-break-out newMessageFieldBox__input w-full'
                        role='presentation'
                        id={ `${ idForField }_wrapper` }
                     >
                        <div
                           placeholder={ isMassMessage ? `Type your message...` : 'Enter your message' }
                           role='presentation'
                           className={ cx({
                              [`editable-input dont-break-out text-major dark:text-major-dark cursor-text min-h-[57px]`]: true,
                              'max-h-[30vh]': isMassMessage,
                              'opacity-50 !cursor-default': isLoadingSendBtn,
                              [`text-left `]: isSavedReplies,
                           }) }
                           id={ idForField }
                           contentEditable={ !isLoadingSendBtn }
                           onFocus={ (e) => {
                              if(!isConnected) {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 inputRef.current.blur()
                              }
                              !!onInputFocus && onInputFocus()
                              onReadMessage()
                              if(mutedInfo && mutedInfo.isMuted) {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 inputRef.current.blur()
                                 setMutedMemberInfo({ isMutedModal: true })
                              } else {
                                 inputForIosFocus(true)
                              }
                           } }
                           onKeyUp={ (e) => {
                              if(13 === e.nativeEvent.keyCode) {
                                 e.preventDefault();
                                 return
                              }
                              if(13 !== e.nativeEvent.keyCode) {
                                 onUserTyping()
                                 if(isMessageToLong) {
                                    setErrorToLong(false)
                                 }
                              }
                           } }
                           onKeyDown={ onSendMessage }
                           ref={ inputRef }
                           onBlur={ onBlur }
                           onInput={ (e) => {
                              console.log('onInput', e);

                              const text = inputRef.current.innerText.trim();

                              if(isSavedReplies && !!calcTextLengthFor) {
                                 if(!!onCustomAction) {
                                    // onCustomAction('text_length', e?.target?.innerText?.length, text)
                                    onCustomAction('text_length', calcTextLengthFor(text?.trim().slice(0, textLimit)), text?.trim().slice(0, textLimit))
                                 }
                                 if(calcTextLengthFor(text) > textLimit) {
                                    inputRef.current.innerText = text?.slice(0, textLimit - 1)
                                    // inputRef.current.innerText = text

                                    toast.error(`${ textLimit } character limit has been reached`, {
                                       toastId: 'limit_error',
                                    })
                                    inputRef.current.blur()
                                    // inputRef.current.innerText = 44444
                                 }
                                 if(!([46, 8].includes(e.keyCode))) {
                                    return
                                 }
                              }

                              if(text.length >= 20000) {
                                 e.preventDefault()

                                 toast.error('20000 character limit has been reached', {
                                    toastId: 'limit_error',
                                 })
                                 return
                              }

                              e.preventDefault()
                              e.stopPropagation()

                              if(text.length >= textLimit) {
                                 e.preventDefault()

                                 toast.error(`${ textLimit } character limit has been reached`, {
                                    toastId: 'limit_error',
                                 })

                                 return
                              }

                              if(updateMessageType === 'text_message') {
                                 if(disabledSendTo !== !text) {
                                    setDisabledSendTo(!text)
                                 } else if(tempEditableMessage === text && isUpdateState) {
                                    setDisabledSendTo(true)
                                 }
                              } else {
                                 setDisabledSendTo(tempEditableMessage === text)
                              }

                              if(mutedInfo && mutedInfo.isMuted) {
                                 return
                              }
                              let cursorPoision = getCurrentPosition()
                              if(cursorPoision === text.length) {
                                 inputRef.current.scrollTop = inputRef.current.scrollHeight + 100
                              }

                              if(!isMassMessage) {
                                 saveAsDraftTextMessage(text)
                              }

                           } }
                           onPaste={ (e) => {
                              console.log('onPaste', e);

                              let clipboardData, pastedData;
                              e.stopPropagation();
                              e.preventDefault();
                              clipboardData = e.clipboardData || window.clipboardData;
                              pastedData = clipboardData.getData('Text');

                              if(inputRef?.current) {

                                 if(isSavedReplies && !!calcTextLengthFor) {
                                    if(calcTextLengthFor(inputRef?.current?.innerText?.trim()) + pastedData?.length > textLimit) {
                                       toast.error(`${ textLimit } character limit has been reached`, {
                                          toastId: 'limit_error',
                                       })
                                       pastedData = pastedData.slice(0, textLimit - inputRef?.current?.innerText?.length)
                                    }

                                 } else {
                                    if(inputRef?.current?.innerText?.length + pastedData?.length > textLimit) {
                                       toast.error(`${ textLimit } character limit has been reached`, {
                                          toastId: 'limit_error',
                                       })
                                       pastedData = pastedData.slice(0, textLimit - inputRef?.current?.innerText?.length)
                                    }
                                 }

                              }
                              window.document.execCommand('insertText', false, pastedData);
                              if(!!onCustomAction && !!calcTextLengthFor) {
                                 onCustomAction('text_length', calcTextLengthFor(e?.target?.innerText?.trim()), inputRef.current?.innerText?.trim())
                              }
                              if(isMassMessage && !!onCustomAction) {
                                 onCustomAction('body', inputRef?.current?.innerText)
                              } else {
                                 saveAsDraftTextMessage(pastedData)
                              }
                              const positionCursor = getCurrentPosition()
                              setCursorPosition(positionCursor);
                           } }
                        >
                        </div>
                     </div>
                     {/* {
                        <p className='text-error'>{inputRef?.current?.textContent?.length} of 1000</p>
                     } */}
                  </div>
                  {
                     isShowUser && (
                        <div
                           className={ cx({
                              'h-11 w-11 min-w-avatar rounded-full': true,
                              'mx-3': !isMobile && showFrequentlyUsedEmojis,
                           }) }
                        >
                           <img
                              alt='avatar'
                              src={ userAvatar }
                              className='comment field h-full w-full rounded-full object-cover object-center' />
                        </div>
                     )
                  }
                  {
                     children && (
                        <div
                           className={ cx({
                              'px-3.5': !isMobile && showFrequentlyUsedEmojis,
                           }) }
                        >
                           {children}
                        </div>
                     )
                  }
                  {
                     (!isMassMessage && isUpdateState) &&
                     <Button
                        padding='none'
                        classNames={ cx({
                           '!w-fit mb-4': true,
                           'ml-3': !isMobile && showFrequentlyUsedEmojis,
                        }) }
                        backgroundColor='transparent'
                        textColor='secondary dark:text-secondary-dark'
                        textSize='small'
                        text='Cancel'
                        onClick={ onClickCancelEditMessage }
                        textMarginX='0'
                     />
                  }
                  <div
                     className={ cx({
                        'w-full flex justify-between': true,
                        'px-3.5': !isMobile && showFrequentlyUsedEmojis,
                     }) }
                  >
                     <ChatOptions
                        uploadFile={ (e, type) => {
                           onUploadFile(e, type)
                        } }
                        onClick={ async (type, resourceType) => {
                           switch (type) {
                              case 'voice':
                                 // setMessageType(type)
                                 if(showContent || !!inputRef?.current?.innerText) {
                                    setIsOpenDiscardModal(true)
                                    return
                                 }
                                 initDevices()
                                 break;
                              case 'saved_replies':
                                 // setMessageType(type)
                                 console.log({ type, resourceType });
                                 onClickSavedReplies()
                                 break;

                              default:
                                 // setMessageType(type)
                                 onSelectLibery(resourceType, type)
                                 break;
                           }
                        } }
                        optionsData={ getOptionsData() }
                        isMobile={ isMobile }
                        isAdmin={ isAdmin }
                        isMessageToLong={ isMessageToLong }
                        switchShowEmojis={ () => switchShowEmojis() }
                        showEmojis={ showEmojis }
                        addEmoji={ addEmoji }
                        isUpdateState={ isUpdateState }
                        isMiniChat={ isMiniChat }
                        showVariables={ showVariables }
                        variableList={ variableList }
                        inputRef={ inputRef }
                        onCustomAction={ onCustomAction }
                        showFrequentlyUsedEmojis={ showFrequentlyUsedEmojis }
                        isDemoMode={ isDemoMode }
                        disableAttachments={ disableAttachments }
                     />
                     {
                        !isMassMessage && !isSavedReplies &&
                           <div
                              className={ cx({
                                 'items-center flex ': true,
                                 'ml-4': !intoNotificationCard,
                                 'ml-1': intoNotificationCard,
                              }) }
                           >
                              <Button
                                 iconName={ isLoadingSendBtn || isUpdateState ? '' :  'send' }
                                 text={ isUpdateState ? 'Save' : 'Send' }
                                 textMarginX='0'
                                 textClassName='mr-2'
                                 textSize='small'
                                 primaryColor
                                 classNames={ `${ showContent || !disabledSendTo || prevFiles.length ? '' : 'opacity-50 cursor-default' } !p-0 flex !items-center` }
                                 disabled={ disabledSendTo && !showContent }
                                 fontIconSize='extraLarge'
                                 backgroundColor='transparent'
                                 onClick={ () => onSendMessage({ keyCode: 13 }) }
                                 loaderClassName='!border-[1px] !w-4 !h-4'
                                 isLoading={ isLoadingSendBtn }
                              />
                           </div>
                     }
                  </div>
               </div>
            </div>
            {
               isOpenDiscardModal && (
                  <DiscardChangeMethodModal
                     action={ () => {
                        onClearState()
                        inputRef.current.innerText = ''
                        setIsOpenDiscardModal(false)
                        initDevices()

                        if(isSavedReplies) {
                           setReplyData({
                              attachments: null,
                              attachmentsForShow: null,
                              body: '',
                              locked_poster_is_blur: null,
                              allow_download: null,
                              unlock_price: null,
                              voice_message_data_src: null,
                           }, repliesModalState)
                        }
                     } }
                     onCloseModal={ () => {
                        setIsOpenDiscardModal(false)
                     } }
                     buttonText='Discard and record voice message'
                     isMobile={ isMobile }
                     description={ `You have ${ isSavedReplies ? 'unsaved' : 'unsent' } text message or attachments. Adding a voice message will discard them. Do you want to continue?` }
                  />
               )
            }
         </div>

      </>
   );
}
TextMessageField.propTypes = {
   isMobile: PropTypes.bool,
   isAdmin: PropTypes.bool,
   isMuted: PropTypes.bool,
   sendMessage: PropTypes.func,
   onUserTyping: PropTypes.func,
   canelReplyMessage: PropTypes.func,
   replyMessage: PropTypes.object,
   text: PropTypes.string,
   mutedInfo: PropTypes.object,
   inputRef: PropTypes.any,
   inputForIosFocus: PropTypes.func,
   onReadMessage: PropTypes.func,
   setMutedMemberInfo: PropTypes.func,
   isConnected: PropTypes.bool,
   isMessageToLong: PropTypes.bool,
   setErrorToLong: PropTypes.func,
   isLivstream: PropTypes.bool,
   isShowUser: PropTypes.bool,
   userAvatar: PropTypes.string,
   onSelectLibery: PropTypes.func,
   prevFiles: PropTypes.any,
   onUploadFile: PropTypes.func,
   sendVoiceMessage: PropTypes.func,
   showContent: PropTypes.bool,
   privateAttachmentSettings: PropTypes.object,
   isGroup: PropTypes.bool,
   defaultVoicePrice: PropTypes.number,
   intoNotificationCard: PropTypes.bool,
   onUpdateRecordStatus: PropTypes.func,
   children: PropTypes.any,
   containerRef: PropTypes.any,
   isLoadingSendBtn: PropTypes.bool,
   setIsLoadingSendBtn: PropTypes.func,
   isUpdateState: PropTypes.bool,
   onClickCancelEditMessage: PropTypes.func,
   setDisabledSendTo: PropTypes.func,
   disabledSendTo: PropTypes.bool,
   showNickname: PropTypes.bool,
   isMiniChat: PropTypes.bool,
   hideVoiceMessage: PropTypes.bool,
   isMassMessage: PropTypes.bool,
   onCustomAction: PropTypes.func,
   showVariables: PropTypes.bool,
   variableList: PropTypes.array,
   showFrequentlyUsedEmojis: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   authUser: PropTypes.object,
   onClearState: PropTypes.func,
   disableAttachments: PropTypes.bool,
   updateMessageType: PropTypes.string,
   isMaxAttachmentCountReached: PropTypes.bool,
   saveAsDraftTextMessage: PropTypes.func,
   onClickSavedReplies: PropTypes.func,
   idForField: PropTypes.string,
   textLimit: PropTypes.number,
   isSavedReplies: PropTypes.bool,
   onInputBlur: PropTypes.func,
   calcTextLengthFor: PropTypes.func,
   setReplyData: PropTypes.func,
   repliesModalState: PropTypes.string,
   replyData: PropTypes.any,
   setMessageType: PropTypes.func,
   errors: PropTypes.any,
   onInputFocus: PropTypes.func,
}

TextMessageField.defaultProps = {
   isAdmin: false,
   sendMessage: () => {},
   onReadMessage: () => {},
   onUserTyping: () => {},
   inputForIosFocus: () => {},
   isShowUser: false,
   isLivstream: false,
   onClickCancelEditMessage: () => {},
   isMiniChat: false,
   showFrequentlyUsedEmojis: false,
   isDemoMode: false,
   authUser: '',
   onClearState: () => {},
   updateMessageType: '',
   saveAsDraftTextMessage: () => {},
   textLimit: 20000,
   idForField: 'chat-field',
}
export default TextMessageField;
