import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {

   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetching: false,
         data: payload,
         isEmpty: payload.length === 0,
      }
   },

   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.DELETE_TAG_START]: (state) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: true,
         },
      }
   },

   [types.DELETE_TAG_COMPLETED]: (state, action) => {
      const { payload } = action;
      const newData = [...state.data].filter(i => i.id !== payload)
      return {
         ...state,
         data: newData,
         modalSettings: {
            ...state.modalSettings,
            isOpen: false,
            tagName: '',
            activeId: null,
            buttonDisabled: false,
         },
         isEmpty: newData.length === 0,
      }
   },

   [types.DELETE_TAG_FAILED]: (state, action) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: false,
         },
      }
   },

   [types.UPDATE_TAG_START]: (state) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: true,
         },
      }
   },

   [types.UPDATE_TAG_COMPLETED]: (state, action) => {
      const { payload } = action;
      const newData = [...state.data].map(i => {
         const { ...elm } = i
         if(elm.id === payload.id) {
            elm.name = payload.name
         }
         return elm
      })
      return {
         ...state,
         data: newData,
         modalSettings: {
            ...state.modalSettings,
            isOpen: false,
            tagName: '',
            activeId: null,
            buttonDisabled: false,
         },
      }
   },

   [types.CREATE_NEW_TAG_START]: (state) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: true,
         },
      }
   },

   [types.CREATE_NEW_TAG_COMPLETED]: (state, action) => {
      const { payload } = action;
      let newData = [...state.data];
      if(!state.data.find(item => item.id === payload.id)) {
         newData = [{ ...payload }, ...state.data]
      }
      return {
         ...state,
         data: newData,
         isEmpty: newData.length === 0,
         modalSettings: {
            ...state.modalSettings,
            isOpen: false,
            tagName: '',
            activeId: null,
            buttonDisabled: false,
         },
      }
   },

   [types.CREATE_NEW_TAG_FAILED]: (state, action) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: false,
         },
      }
   },
   [types.UPDATE_TAG_FAILED]: (state, action) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: false,
         },
      }
   },

   [types.SET_MODAL_SETTINGS]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            ...payload,
         },
      }
   },
   [types.CREATE_NEW_TAG_ERROR]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         errors: {
            ...state.errors,
            success: payload.success,
            name: payload.name,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
