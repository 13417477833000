export const FETCH_DETAILS_START = "tags/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "tags/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "tags/FETCH_DETAILS_FAILED";
export const DELETE_TAG_START = "tags/DELETE_TAG_START";
export const DELETE_TAG_COMPLETED = "tags/DELETE_TAG_COMPLETED";
export const DELETE_TAG_FAILED = "tags/DELETE_TAG_FAILED";
export const UPDATE_TAG_START = "tags/UPDATE_TAG_START";
export const UPDATE_TAG_COMPLETED = "tags/UPDATE_TAG_COMPLETED";
export const UPDATE_TAG_FAILED = "tags/UPDATE_TAG_FAILED";
export const CREATE_NEW_TAG_START = "tags/CREATE_NEW_TAG_START";
export const CREATE_NEW_TAG_COMPLETED = "tags/CREATE_NEW_TAG_COMPLETED";
export const CREATE_NEW_TAG_FAILED = "tags/CREATE_NEW_TAG_FAILED";
export const SET_MODAL_SETTINGS = "tags/SET_MODAL_SETTINGS";

export const CREATE_NEW_TAG_ERROR = "tags/CREATE_NEW_TAG_ERROR"