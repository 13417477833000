
import React, {  useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import OutgoingNotificationsAndAutoTweetEditContainer from 'admin/containers/pages/my-site/settings/outgoing-notification-and-auto-tweet/edit/index.js';
import { isFetchingBySlugSelector } from 'admin/state/modules/outgoing-notifications/selectors';
import { repostContentOperation } from 'admin/state/modules/common/operations';
import Button from 'common/components/elements/buttons/primary';
import { ARTICLE_ID_ON_BLUR_WHEN_LOCKED } from 'common/utils/intercom-articles';
import Intercom from 'common/utils/Intercom';


function ContentRepostModal({
   onCloseModal,
   insideModalSlug,
   resourceId,
   callbackFunction,
   initialData,
}) {
   const screenWidth = useSelector(screenWidthSelector)
   const isFetchingData = useSelector(isFetchingBySlugSelector)
   const dispatch = useDispatch()
   const childrenRef = useRef(null)
   const [data, setData] = useState(null)
   const [isLoadingPostBtn, setIsLoadingPostBtn] = useState(false)
   const [isDisabledPostBtn, setIsDisabledPostBtn] = useState(false)
   const [isOpenWarningModal, setIsOpenWarningModal] = useState(false)

   useEffect(() => {
      let height = window.innerHeight * 0.9
      let elementsHeight =  265
      let maxHeight = height - elementsHeight
      childrenRef.current.style.maxHeight = `${ maxHeight }px`
   }, [screenWidth])

   useEffect(() => {
      if(!!data) {
         setIsDisabledPostBtn(!data?.auto_tweet_is_on && !data?.mass_private_message_is_on)
      }
   }, [data])

   const onClickPost = (bool) => {

      setIsLoadingPostBtn(true)
      let types = {
         new_photoset_published: 'photo-collections',
         new_bundle_published: 'products',
         new_video_published: 'videos',
      }
      dispatch(
         repostContentOperation(
            types?.[insideModalSlug], { ...data, send_anyway: bool }, resourceId,
            (type, date) => {
               if(type === 'error') {
                  setIsLoadingPostBtn(false)
                  return
               } else if(type === 'validationError') {
                  setIsOpenWarningModal(true)
                  setIsLoadingPostBtn(false)
                  return
               } else {
                  if(callbackFunction) callbackFunction(date)
               }
               onCloseModal(false)
            })
      )
   }

   return (
      <>
         <CreateEditModal
            type='confirm'
            icon='repost'
            iconColor='action'
            action={ () => onClickPost(false) }
            onCloseModal={ () => onCloseModal(false) }
            title='Repost'
            withoutCloseButton={ false }
            withoutCancelButton
            buttonText='Post'
            contentWidth={ isFetchingData ? screenWidth < 1025 ? '100vw' : '1000px' : 'auto' }
            isMobile={ screenWidth < 1025 }
            iconSize='2xl'
            buttonClassName='mt-6'
            confirmButtonProperties={ {
               backgroundColor: 'action',
               borderRadius: 'large',
               classNames: '!py-3 !px-5',
               primaryColor: true,
               textColor: 'major-dark',
               textClassName: '!font-medium !text-base !leading-5 !mx-0',
            } }
            className='!pb-4'
            isPreventDeafault={ false }
            isLoading={ isLoadingPostBtn }
            confirmButtonHight='11'
            disabled={ isDisabledPostBtn }
         >
            <div
               className={ cx({
                  'mt-6 overflow-y-auto ams-custom-scrool w-full': true,
                  'h-full flex items-center min-h-[350px]': isFetchingData,
               }) }
               ref={ childrenRef }
            >
               <OutgoingNotificationsAndAutoTweetEditContainer
                  isInsideModal
                  insideModalSlug={ insideModalSlug }
                  insideModalCallBack={ (data) => {
                     setData({ ...data })
                  } }
                  insideModalDefaultData={ initialData }
               />
            </div>
         </CreateEditModal>
         {
            isOpenWarningModal &&
            <CreateEditModal
               type='action'
               icon='twitter-x'
               iconColor='action'
               descriptionColorClass='text-secondary'
               action={ () => setIsOpenWarningModal(prev => !prev) }
               onCloseModal={ () => setIsOpenWarningModal(prev => !prev) }
               title='Auto post (tweet) image NOT blurred'
               cancelText='Keep editing'
               buttonText='Cancel'
               nonPrimaryColor
               textColor='error'
               contentWidth='375px'
               isMobile={ screenWidth < 1025 }
               buttonClassName='text-error'
               iconSize='2xl'
               buttonBackgroundColor='transparent'
               withoutCloseButton
               otherClassName={ `ams-custom-scrool` }
               closeOnClickOutside
               secondButtonTitle='Yes, post to X'
               secondButtonClassName='mb-3'
               secondButtonPosition='top'
               secondButtonAction={ () => onClickPost(true) }
               overrideSecondButtonProps={ {
                  fontIconSize: 'large',
                  textColor: 'action',
                  fontIconColor: 'action',
               } }
               buttonTextClassName={ '!text-secondary' }
               isCloseAction
               titleClassName='text-center !leading-6'
            >
               <p className='text-secondary text-base leading-5 text-center mb-5'>
                  The thumbnail that will be posted to X will NOT be blurred
               </p>
               <div className='flex items-center justify-center my-3'>
                  <Button
                     text={ `More info on blur when locked` }
                     borderRadius='large'
                     padding='none'
                     classNames='w-fit !h-9 intercom-more-info-on-blur-when-locked'
                     fontWeight='medium'
                     iconName={ `attention-outline` }
                     textSize='small'
                     textColor='action dark:text-action-dark'
                     fontIconColor='action'
                     darkIconColor='action-dark'
                     backgroundColor='transparent'
                     iconPosition='left'
                     iconSize='small'
                     fontIconSize='large'
                     onClick={ (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        Intercom.showArticle(ARTICLE_ID_ON_BLUR_WHEN_LOCKED)
                     } }
                  />
               </div>
            </CreateEditModal>
         }
      </>
   )
}

ContentRepostModal.propTypes = {
   onCloseModal: PropTypes.func,
   insideModalSlug: PropTypes.string,
   resourceId: PropTypes.number,
   callbackFunction: PropTypes.func,
   initialData: PropTypes.object,
}

export default ContentRepostModal
