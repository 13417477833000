import React, { useState } from 'react'
import PropTypes from 'prop-types';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import Button from 'common/components/elements/buttons/primary';
import DeleteModal from 'admin/views/manage-resource/delete-modal';
import Modal from 'common/components/modules/modal';
import { getCountState } from 'common/utils/utils'
import './style.scss'

const BulkEditButton = ({
   isBulkEdit,
   onBulkEdit,
   setIsBulkEdit,
   onSelectAll,
   isSelectAll,
   selectedLength,
   destroyButtonDisabled,
   destroyMedais,
   type = 'video',
   setSelectedCount,
   totalCount,
   onCancel,
   isReorderMode,
   isDemoMode,
}) => {

   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   return (
      <div className={ `flex justify-between bulk-edit-buttons ${ isBulkEdit ? ' w-full sm:flex-row flex-col ' : '' }` }>
         {
            !isBulkEdit && (
               <div
                  className='h-11 inline-block'
               >
                  <Button
                     text='Bulk edit'
                     textColor='major'
                     fontIconColor='secondary'
                     iconName='pencil'
                     // fontIconSize='base'
                     iconPosition='left'
                     padding='none'
                     classNames='h-full px-5 py-[10px] whitespace-nowrap border border-divider  '
                     textMarginX='0'
                     // primaryColor
                     onClick={ () => setIsBulkEdit(true) }
                     borderRadius='large'
                     backgroundColor='panel'
                     waveColor='rgba(33, 33, 33, 0.8)'
                     disabled={ isReorderMode }
                     textClassName={ `${ isReorderMode ? '!text-disabled' : '' } ml-2` }
                     iconClassName={ `${ isReorderMode ? '!text-disabled' : '' }` }
                     textSize='base'
                  />
               </div>

            )
         }
         {
            isBulkEdit && (
               <>
                  <div className='flex items-center'>
                     <div
                        className='h-11 inline-block'
                     >
                        <Button
                           text={  `Edit ${ selectedLength > 0 ? `${ selectedLength } selected` : '' }` }
                           fontWeight='bold'
                           iconName='pencil'
                           // fontIconSize='base'
                           iconPosition='left'
                           padding='none'
                           classNames='h-full px-5 py-[10px]'
                           primaryColor
                           onClick={ onBulkEdit }
                           borderRadius='large'
                           disabled={ selectedLength === 0 }
                        />
                     </div>
                     <div
                        className='h-9 inline-block ml-3'
                     >
                        <Button
                           text='Cancel'
                           textSize='base'
                           textColor='secondary'
                           padding='none'
                           classNames='h-full px-5 py-[10px]'
                           onClick={ () => {
                              setIsBulkEdit(false);
                              if(onCancel){
                                 onCancel();
                              }
                           } }
                           borderRadius='large'
                           backgroundColor='transparent'
                           fontWeight='medium'
                        />
                     </div>
                  </div>
                  <div className='flex items-center sm:mt-0 mt-3'>
                     <div
                        className='h-9 inline-block select-all'
                     >
                        <CheckboxLabel
                           label={ `Select ${ totalCount !== 1 ? 'all' : '' } ${ getCountState(totalCount, type) } ` }
                           onChange={ () => onSelectAll(!isSelectAll) }
                           checked={ isSelectAll }
                           name='select_all'
                           textColor='secondary'
                           padding='none'
                           className='select-all-checkbox'
                           fontWeight='medium'
                        />
                     </div>
                     <div
                        className='h-9 ml-3 inline-block'
                     >
                        <Button
                           text='Delete'
                           textSize='base'
                           textColor='error'
                           padding='none'
                           classNames={ `h-full px-3 ${ selectedLength === 0 ? 'opacity-50 cursor-not-allowed' : '' }` }
                           onClick={ selectedLength === 0 ? null : () => setOpenDeleteModal(true) }
                           borderRadius='large'
                           backgroundColor='transparent'
                           disabled={ selectedLength === 0 || isDemoMode }
                           dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
                           dataToolTipId='ams-top-tooltip-start'
                        />
                     </div>
                  </div>
               </>
            )
         }
         {
            openDeleteModal && (
               <Modal
                  contentPosition={ 'center' }
                  onClose={ () => setOpenDeleteModal(false) }
                  isCloseAction={ false }
               >
                  <DeleteModal
                     title={ `Type "delete ${ getCountState(selectedLength, type) }" to confirm` }
                     type='video'
                     isBulkEdit={ true }
                     count={ getCountState(selectedLength, type) }
                     checkTrue={ `delete ${ getCountState(selectedLength, type) }` }
                     isDisabled={ destroyButtonDisabled }
                     deleteCollection={ () => {
                        destroyMedais(() => {
                           setOpenDeleteModal(false)
                           setIsBulkEdit(false)
                           setSelectedCount(0)
                        })
                     } }
                     onClickCancel={ () => setOpenDeleteModal(false) } />
               </Modal>
            )
         }
      </div>
   )
}

BulkEditButton.propTypes = {
   isBulkEdit: PropTypes.bool,
   onBulkEdit: PropTypes.func,
   setIsBulkEdit: PropTypes.func,
   onSelectAll: PropTypes.func,
   isSelectAll: PropTypes.bool,
   selectedLength: PropTypes.number,
   totalCount: PropTypes.number,
   destroyMedais: PropTypes.func,
   onCancel: PropTypes.func,
   destroyButtonDisabled: PropTypes.bool,
   setSelectedCount: PropTypes.any,
   type: PropTypes.string,
   isReorderMode: PropTypes.bool,
   isDemoMode: PropTypes.bool,
}
export default BulkEditButton
