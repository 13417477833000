
import { createSelector } from 'reselect'

const innerStateSelector = state => state.videos;

const videosDataSelector = createSelector(
   innerStateSelector,
   state => state.videosData
);
const videosDataInsideModal = createSelector(
   innerStateSelector,
   state => state.videosDataInsideModal
);

const isVideosDataFetching = createSelector(
   innerStateSelector,
   state => state.isFetching
);
const isVideosDataInsideModalFetching = createSelector(
   innerStateSelector,
   state => state.isFetchingInsideModal
);

const isNotCompletedVideosFetching = createSelector(
   innerStateSelector,
   state => state.isNotCompletedFetching
);

const notCompletedList = createSelector(
   innerStateSelector,
   state => state.notCompletedList
);
const fetchVideoByIdSelector = (state) => {
   let activeCollection = {}
   if(state.resources.resources.video_collections) {
      activeCollection = state.resources.resources.video_collections.find(value => value.id === state.videos.fetchVideoById.collection_id)
   }
   return { ...state.videos.fetchVideoById, activeCollection }
}

const isFetchingByIdSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingById
);
const updateButtonDisabledSelector = createSelector(
   innerStateSelector,
   state => state.updateButtonDisabled
);
const createErrorsDataSelector = createSelector(
   innerStateSelector,
   state => state.createErrorsData
);
const isErrorsSelector = createSelector(
   innerStateSelector,
   state => state.isErrors
);

const isFetchingByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingByFilter
);
const isFetchingByFilterInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingByFilterInsideModal
);
const isNewFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isNewFetching
);
const isNewFetchingInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isNewFetchingInsideModal
);
const isEmptyByFilterInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilterInsideModal
);
const isEmptyByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilter
);
const isEmptySelector = createSelector(
   innerStateSelector,
   state => state.isEmpty
);
const isEmptyInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyInsideModal
);

const deleteButtonDisabledSelector = createSelector(
   innerStateSelector,
   state => state.deleteButtonDisabled
);
const openEditTitelModSelector = createSelector(
   innerStateSelector,
   state => state.openEditTitelMod
);
const openStatusModSelector = createSelector(
   innerStateSelector,
   state => state.openStatusMod
);
const updateStatusFetchingSelector = createSelector(
   innerStateSelector,
   state => state.updateStatusFetching
);
const isInitedSelector = createSelector(
   innerStateSelector,
   state => state.isInited
);
const filtersSelector = createSelector(
   innerStateSelector,
   state => state.filters
);
const isDistoryFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isDistoryFetching
);
const bulkEditIdsSelector = createSelector(
   innerStateSelector,
   state => state.bulkEditIds
);
const bulkVideosSelector = createSelector(
   innerStateSelector,
   state => state.bulkVideos
);
const isFetchingByBulkVideosSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingByBulkVideos
);
const bulkUpdateFetchingSelector = createSelector(
   innerStateSelector,
   state => state.bulkUpdateFetching
);
const bulkErrorsSelector = createSelector(
   innerStateSelector,
   state => state.bulkErrors
);
const replaceStateSelector = createSelector(
   innerStateSelector,
   state => state.replaceState
);
const warningModalSelector = createSelector(
   innerStateSelector,
   state => state.warningModal
);
const filtersInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.filtersInsideModal
);

export {
   videosDataSelector,
   isVideosDataFetching,
   isNotCompletedVideosFetching,
   notCompletedList,

   fetchVideoByIdSelector,
   isFetchingByIdSelector,
   updateButtonDisabledSelector,
   createErrorsDataSelector,
   isErrorsSelector,
   isFetchingByFilterSelector,
   isNewFetchingSelector,

   isEmptySelector,
   isEmptyByFilterSelector,

   deleteButtonDisabledSelector,
   openEditTitelModSelector,
   openStatusModSelector,
   updateStatusFetchingSelector,
   videosDataInsideModal,
   isVideosDataInsideModalFetching,

   isFetchingByFilterInsideModalSelector,
   isNewFetchingInsideModalSelector,
   isEmptyByFilterInsideModalSelector,
   isEmptyInsideModalSelector,
   isInitedSelector,
   filtersSelector,
   isDistoryFetchingSelector,

   bulkEditIdsSelector,
   bulkVideosSelector,
   isFetchingByBulkVideosSelector,
   bulkUpdateFetchingSelector,
   bulkErrorsSelector,
   replaceStateSelector,
   warningModalSelector,
   filtersInsideModalSelector,
}
