import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import QuillEditor from './QuillEditor';
import toast from 'common/utils/toast';
import { quillUtils } from 'common/utils/utils';
import randomstring from 'randomstring';
import { converDesriptionVarables } from "common/utils/utils";
// import { Quill } from 'react-quill';

const utils = quillUtils();
// const Delta = Quill.import('delta')
const EditorConvertToHTML = ({
   refProps,
   toolbar,
   handleChange,
   // height,
   initialValue,
   value,
   isMobile,
   placeholder,
   variableList,
   limit,
   isShowEmoji,
   isShowVariableButton,
   errorMessageClassName,
   label,
   errorMessage,
   onCharCount,
   showOptional,
   // descriptionPosition,
   children,
   handleEditorBlur,
   // isTextFormat = false,
   handleEditorFocus,
   readOnly,
   showSourceCode,
   scrollingContainer,
   bounds,
   className,
}) => {
   const [description, setDescription] = useState(value || initialValue);
   const uniqId = useRef(randomstring.generate(12));
   const [showError, setShowError] = useState(false);
   const [count, setCount] = useState(null);
   const [isInited, setIsInited] = useState(false);
   const toolbarRef = useRef();

   // const [othersToolIndex, setOthersToolIndex] = useState(0)
   const [lightDarkMode, setLightDarkMode] = useState(!!window.site_dark_bg ? 'dark' : 'light')
   let editorRef = useRef(null)
   let editorContainer = useRef(null)
   const editorValueRef = useRef(null)
   if(refProps) {
      editorRef = refProps
   }
   useEffect(() => {
      let editorValue = value || initialValue || '';
      if(variableList?.length && isShowVariableButton){
         editorValue = converDesriptionVarables(editorValue, variableList);
      }
      setDescription(editorValue);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   // const [isFecth, setIsFetch] = useState(true)

   // const asd = () => {
   //    const CARD_FIX_WIDTH = 36;
   //    const CARD_MARGIN = 8;
   //    const toolWidth = CARD_FIX_WIDTH + CARD_MARGIN;
   //    const width = editorContainer.current.offsetWidth - toolWidth;// Diff more icons width
   //    const maxCount = Math.round(width / toolWidth);
   //    if(!othersToolIndex !== maxCount){
   //       setOthersToolIndex(maxCount - 1)
   //    }
   //    console.log(maxCount, 'maxCount')
   // }

   // useEffect(() => {
   //    window.addEventListener("resize", asd);

   //    return () => {
   //       window.removeEventListener("resize", asd);
   //    }
   // // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [othersToolIndex])

   // useEffect(() => {
   //    if(editorContainer?.current && isFecth){
   //       asd()
   //       setIsFetch(false)

   //    }
   // // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [isFecth, othersToolIndex])

   useEffect(() => {
      if(!isInited){
         if(toolbar?.length){
            if(toolbarRef.current){
               setIsInited(true)
            }
         } else {
            setIsInited(true)
         }
      }
      if(editorRef.current && isInited){
         const quill = editorRef.current.getEditor();
         editorRef.current.editor.root.setAttribute('id', `editor_${ uniqId.current }`)
         if(readOnly){
            editorRef.current.editor.enable(false);
         }
         let count = charCount(quill);
         editorValueRef.current = editorRef.current.editor.root.innerHTML
         setCount(count);
         const container = quill.root.parentElement;
         const resize = (delta, oldDelta, source, ...asd) => {
            container.style.height = 'auto';
            container.style.height = `${ quill.root.scrollHeight + 20 }px`;
         };
         resize();
         // quill.clipboard.addMatcher(Node.TEXT_NODE, function(node, delta) {
         //    console.log(node, delta)
         //    return new Delta().insert(node.data);
         // });
         quill.on('text-change', resize);
         return () => {
            quill.off('text-change', resize);
         };
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [toolbarRef, isInited, toolbar?.length, editorRef.current])


   useEffect(() => {
      setLightDarkMode(!!window.site_dark_bg ? 'dark' : 'light')
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [window?.site_dark_bg]);

   useEffect(() => {
      if(errorMessage) {
         setShowError(true);
      }
   }, [errorMessage]);

   const charCount = (editor) => {
      let text = editorRef?.current?.editor?.root?.innerText || editor?.getText() || '';
      let html = editorRef?.current?.editor?.root?.innerHTML || editor?.getHTML() || '';
      if(!text){
         return 0;
      }
      if(!text.trim()){
         return 0
      }
      // if(limit && limit < text.length){
      //    text = text.trim();
      // }
      if(onCharCount){
         return onCharCount(html);
      }
      return html.length;
   };
   // Function to sanitize links and add https:// if missing
   const sanitizeLinks = (inputHtml) => {
      const div = document.createElement('div');
      div.innerHTML = inputHtml;
      const links = div.getElementsByTagName('a');

      // Loop through all links in the editor content
      for(let link of links) {
         const href = link.getAttribute('href');
         // If the link doesn't start with http or https, prepend https://
         if(!href.startsWith('http://') && !href.startsWith('https://')) {
            link.setAttribute('href', `https://${ href }`);
         }
      }
      return div.innerHTML;
   };
   const backContent = (editor, delta) => {
      if(editor.getText() && !editor.getText().trim()){
         const options = delta?.ops || [];
         const insertNonEmpty = Boolean(options.find(option => option?.insert && '\n' !== option?.insert));
         if(!insertNonEmpty){
            return ''
         }
      }
      if(editor?.getHTML){
         return  sanitizeLinks(editor.getHTML());
      }
      return ''
   }

   const onEditorChange = (html, editor, source, delta) =>  {
      let value = sanitizeLinks(html);
      if(showError && errorMessage){
         setShowError(false);
      }

      const length = charCount(editor);
      setCount(length);
      if(limit && length > limit){
         if(editorValueRef.current){
            editorRef.current.editor.root.innerHTML = editorValueRef.current
            setCount(editorValueRef.current.length);
         }
         // toast.error(`${ limit } character limit reached`, { toastId: 'editor_characters_limit_reached' })
         toast.error(`Character limit reached`, { toastId: 'editor_characters_limit_reached' })
         return
      }
      editorValueRef.current = editorRef?.current?.editor?.root?.innerHTML;
      setDescription(value)
      if(handleChange && 'api' !== source){
         const backValue = backContent(editor, delta);
         handleChange(backValue, value, editor, source);
      }
   }
   function setCustomProperties() {
      const STYLES = {
         light: {
            color: '#616784',
            'border-color': '#F2F4F7',
            background: '#FFFFFF',
            'bg-hover': '#F6F6FF',
            'input-background': '#FFFFFF',
         },
         dark: {
            color: '#FFFFFF',
            'border-color': '#434343',
            background: '#121212',
            'bg-hover': '#181822',
            'input-background': '#2C2C2C',
         },
      };

      const style = STYLES[lightDarkMode];

      if(style) {
         Object.keys(style).forEach(property => {
            document.documentElement.style.setProperty(`--tox-${ property }`, style[property]);
         });
      }
   }

   const handleonKeyPress = e => {
      let hasSelection = false;
      const selection = window.getSelection();
      const isSpecial = utils.isSpecial(e);
      const isNavigational = utils.isNavigational(e);
      const isSelect = utils.isSelect(e);
      if(selection) {
         hasSelection = !!selection.toString();
      }

      if(isSpecial || isNavigational || isSelect) {
         return true;
      }
      if(limit && count >= limit  && !hasSelection) {
         // toast.error(`${ limit } character limit reached`, { toastId: 'editor_characters_limit_reached' })
         toast.error(`Character limit reached`, { toastId: 'editor_characters_limit_reached' })
         e.stopPropagation();
         e.preventDefault();
         return;
      }
   }

   useEffect(() => {
      const editor = document.getElementById('customEditor')
      if(!!editor) setCustomProperties()

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [lightDarkMode])

   const getFontsList  = () => {
      const fontsObj = {
         'Inter': 'Inter, sans-serif',
         'Roboto': 'Roboto, sans-serif',
         'Lobster': 'Lobster, cursive',
         'Abril Fatface': 'Abril Fatface,  cursive',
         'DM Serif Display': 'DM Serif Display,  sans-serif',
         'Anton': 'Anton,  sans-serif',
         'Lato': 'Lato,  sans-serif',
         'Poppins': 'Poppins,  sans-serif',
         'Parisienne': 'Parisienne, cursive',
         'Shrikhand': 'Shrikhand, cursive',
         'Playfair Display': 'Playfair Display, cursive',
         'Roboto Mono': 'Roboto Mono, monospace',
         'Lobster Two': 'Lobster Two, cursive',
         'Montserrat': 'Montserrat,sans-serif',
         'Oswald': 'Oswald, sans-serif',
         'Andale Mono': 'andale mono,times',
         'Arial': 'arial,helvetica,sans-serif',
         'Brush Script MT': 'Brush Script MT, cursive',
         'Arial Black': 'arial black,avant garde',
         'Garamond': 'Garamond, serif',
         'Book Antiqua': 'book antiqua,palatino',
         'Comic Sans MS': 'comic sans ms,sans-serif',
         'Courier New': 'courier new,courier',
         'Georgia': 'georgia,palatino',
         'Helvetica': 'helvetica',
         'Impact': 'impact,chicago',
         'Symbol': 'symbol',
         'Tahoma': 'tahoma,arial,helvetica,sans-serif',
         'Terminal': 'terminal,monaco',
         'Times New Roman': 'times new roman,times',
         'Trebuchet MS': 'trebuchet ms,geneva',
         'Verdana': 'verdana,geneva',
         'Webdings': 'webdings',
         'Wingdings': 'wingdings,zapf dingbats',
         'Raleway': 'Raleway, sans-serif',
      };

      const fonts = []
      Object.keys(fontsObj).forEach(key => {
         fonts.push(key)

      })
      return fonts;
   }
   return (
      <div
         ref={ editorContainer }
         className={
            `flex flex-col QuillCustomEditor relative new-styles mb-6 z-[1]
            ${ !!errorMessage && !!showError ? 'textarea-errors' : '' }
            ${ lightDarkMode === 'dark' ? 'customEditorDarkMode' : 'customEditorLightMode' }
            ${ className }
            `
         }
         id={ `customEditor_${ uniqId.current  }` }
      >
         {
            label && (
               <span className='block font-medium text-base text-major mb-2'>
                  { label }
               </span>
            )
         }
         {
            showOptional && (
               <span className={ `block text-xs text-placeholder dark:text-placeholder-dark mb-2` }>
                  (Optional)
               </span>
            )
         }
         {
            children && (
               <div className='flex items-center mb-2'>
                  {children}
               </div>
            )
         }

         <QuillEditor
            content={ description }
            toolbar={ toolbar }
            lightDarkMode={ lightDarkMode }
            placeholder={ placeholder }
            fontsList={ getFontsList() }
            height={ 120 }
            isShowVariableButton={ isShowVariableButton }
            variableList={ variableList }
            handleEditorBlur={ handleEditorBlur }
            handleEditorFocus={ handleEditorFocus }
            isShowEmoji={ isShowEmoji }
            editorRef={ editorRef }
            handleEditorChange={ onEditorChange }
            limitReached={ limit && count >= limit }
            handleonKeyPress={ handleonKeyPress }
            readOnly={ readOnly }
            id={ uniqId.current }
            showSourceCode={ showSourceCode }
            limit={ limit }
            scrollingContainer={ scrollingContainer }
            backContent={ backContent }
            isMobile={ isMobile }
            bounds={ bounds }
            defaultValue={ initialValue }
            isInited={ isInited }
            toolbarRef={ toolbarRef }
         />
         {
            Boolean(limit && !(errorMessage && showError)) && (
               <span
                  className='text-xs text-secondary dark:text-secondary-dark text-right mt-[6px]'
               >
                  {`${ count || 0 } of ${ limit } characters`}
               </span>
            )
         }
         {
            Boolean(errorMessage && showError) && (
               <span
                  style={ { minHeight: '14px' } }
                  className={ `text-xs text-error dark:text-error-dark text-right ${ errorMessageClassName } mt-[6px]` }
               >
                  {errorMessage}
               </span>
            )
         }
      </div>
   )
}
EditorConvertToHTML.defaultProps = {
   height: 120,
   variableList: [],
   initialValue: '',
   toolbar: ['bold', 'italic', 'underline', 'alignleft', 'aligncenter', 'alignright', 'alignjustify', 'link', 'image'],
   handleEditorBlur: () => {},
   handleEditorFocus: () => {},
   errorMessageClassName: '',
   descriptionPosition: 'right',
   scrollingContainer: 'html',
   bounds: '.QuillCustomEditor',
   className: '',
};
EditorConvertToHTML.propTypes = {
   label: PropTypes.string,
   placeholder: PropTypes.string,
   initialValue: PropTypes.any,
   // height: PropTypes.number,
   isShowVariableButton: PropTypes.bool,
   variableList: PropTypes.array,
   isMobile: PropTypes.bool,
   errorMessage: PropTypes.string,
   toolbar: PropTypes.array,
   limit: PropTypes.number,
   handleChange: PropTypes.func,
   handleEditorFocus: PropTypes.func,
   handleEditorBlur: PropTypes.func,
   value: PropTypes.string,
   refProps: PropTypes.any,
   children: PropTypes.any,
   // isTextFormat: PropTypes.bool,
   isShowEmoji: PropTypes.bool,
   // descriptionPosition: PropTypes.string,
   errorMessageClassName: PropTypes.string,
   scrollingContainer: PropTypes.string,
   onCharCount: PropTypes.any,
   showOptional: PropTypes.bool,
   readOnly: PropTypes.bool,
   showSourceCode: PropTypes.bool,
   bounds: PropTypes.string,
   className: PropTypes.string,
};

export default EditorConvertToHTML;
