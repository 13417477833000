import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal'
import ConfirmModalContent from './confirmation';
import ActionModalContent from './action';
import cx from 'classnames';

const ModalWrapper = ({
   type,
   isMobile,
   onCloseModal,
   action,
   isLoading,
   disabled,
   contentWidth,
   descriptionColorClass,
   title,
   children,
   icon,
   iconColor,
   description,
   buttonText,
   nonPrimaryColor,
   textColor,
   cancelText,
   withoutCancelButton,
   isNeedHelpButton,
   buttonClassName,
   className,
   secondButtonTitle,
   secondButtonClassName,
   secondButtonIconName,
   buttonIconName,
   secondButtonAction,
   secondButtonDisabled,
   iconSize,
   headerClass,
   secondButtonPosition,
   withoutCloseButton,
   primaryButtonClassName,
   cancelButtonAction,
   buttonBackgroundColor,
   withoutConfirmButton,
   secondDescription,
   secondDescriptionClassName,
   isPreventDeafault,
   otherClassName,
   isCloseAction,
   modalWrapperClassName,
   titleClassName,
   closeOnClickOutside,
   closeBtnClassName,
   secondaryButtonClassName,
   titleLeading,
   descriptionLeading,
   maxScreenVersion,
   confirmButtonProperties,
   isStopPropagation,
   modalClassName,
   overrideSecondButtonProps,
   headerNewElementId,
   confirmButtonHight,
   buttonTextClassName,
   isTitleHtml,
   imageAsIconSrc,
}) => {
   const [animate, setAnimate] = useState('up');
   let timer = null
   useEffect(() => {
      return () => {
         clearTimeout(timer)
      }
   }, [timer])

   const closeModal = (e) => {
      if(isMobile) {
         setAnimate('down')
         timer = setTimeout(() => {
            setAnimate('up')
            onCloseModal(e)
         }, 800)
      } else {
         onCloseModal(e)
      }
   }
   return (
      <Modal
         contentPosition={ isMobile ? 'bottom' : 'center' }
         contentWidth={ isMobile ? '100%' : contentWidth }
         onClose={ () => closeModal() }
         isCloseAction={ isCloseAction }
         animate={ isMobile ? animate : null }
         otherClassName={ cx({
            [`modal-wrapper-container ${ otherClassName }`]: true,
            'max-h-[90%] ams-custom-scrool': !isMobile && 'v1' === maxScreenVersion,
            'max-h-[90%]': !isMobile && 'v2' === maxScreenVersion,
            'max-h-[80%]': isMobile,
            [`${ modalWrapperClassName }`]: Boolean(modalWrapperClassName),
         }) }
         closeOnClickOutside={ closeOnClickOutside }
         className={ `h-full ${ modalClassName }` }
      >
         {
            'confirm' === type && (
               <ConfirmModalContent
                  action={ action }
                  isLoading={ isLoading }
                  disabled={ disabled }
                  descriptionColorClass={ descriptionColorClass }
                  title={ title }
                  children={ children }
                  icon={ icon }
                  iconColor={ iconColor }
                  description={ description }
                  buttonText={ buttonText }
                  nonPrimaryColor={ nonPrimaryColor }
                  textColor={ textColor }
                  cancelText={ cancelText }
                  withoutCancelButton={ withoutCancelButton }
                  isNeedHelpButton={ isNeedHelpButton }
                  buttonClassName={ buttonClassName }
                  className={ className }
                  closeModal={ closeModal }
                  iconSize={ iconSize }
                  primaryButtonClassName={ primaryButtonClassName }
                  buttonIconName={ buttonIconName }
                  cancelButtonAction={ cancelButtonAction }
                  withoutConfirmButton={ withoutConfirmButton }
                  secondDescription={ secondDescription }
                  secondDescriptionClassName={ secondDescriptionClassName }
                  withoutCloseButton={ withoutCloseButton }
                  isPreventDeafault={ isPreventDeafault }
                  titleClassName={ titleClassName }
                  closeBtnClassName={ closeBtnClassName }
                  secondaryButtonClassName={ secondaryButtonClassName }
                  titleLeading={   titleLeading }
                  descriptionLeading={ descriptionLeading }
                  secondButtonTitle={ secondButtonTitle }
                  secondButtonClassName={ secondButtonClassName }
                  secondButtonAction={ secondButtonAction }
                  secondButtonDisabled={ secondButtonDisabled }
                  confirmButtonProperties={ confirmButtonProperties }
                  isStopPropagation={ isStopPropagation }
                  headerNewElementId={ headerNewElementId }
                  confirmButtonHight={ confirmButtonHight }
                  isTitleHtml={ isTitleHtml }
                  overrideSecondButtonProps={ overrideSecondButtonProps }
                  imageAsIconSrc={ imageAsIconSrc }
               />
            )
         }
         {
            'action' === type && (
               <ActionModalContent
                  action={ action }
                  isLoading={ isLoading }
                  disabled={ disabled }
                  title={ title }
                  children={ children }
                  icon={ icon }
                  iconColor={ iconColor }
                  buttonText={ buttonText }
                  buttonClassName={ buttonClassName }
                  className={ className }
                  closeModal={ closeModal }
                  secondButtonTitle={ secondButtonTitle }
                  secondButtonClassName={ secondButtonClassName }
                  secondButtonIconName={ secondButtonIconName }
                  buttonIconName={ buttonIconName }
                  secondButtonAction={ secondButtonAction }
                  secondButtonDisabled={ secondButtonDisabled }
                  headerClass={ headerClass }
                  secondButtonPosition={ secondButtonPosition }
                  withoutCloseButton={ withoutCloseButton }
                  buttonBackgroundColor={ buttonBackgroundColor }
                  withoutConfirmButton={ withoutConfirmButton }
                  closeBtnClassName={ closeBtnClassName }
                  overrideSecondButtonProps={ overrideSecondButtonProps }
                  buttonTextClassName={ buttonTextClassName }
                  titleClassName={ titleClassName }
               />
            )
         }
      </Modal>
   );
}


ModalWrapper.propTypes = {
   isMobile: PropTypes.bool,
   action: PropTypes.func,
   onCloseModal: PropTypes.func,
   isLoading: PropTypes.bool,
   disabled: PropTypes.bool,
   contentWidth: PropTypes.string,
   descriptionColorClass: PropTypes.string,
   title: PropTypes.string,
   children: PropTypes.any,
   icon: PropTypes.string,
   iconColor: PropTypes.string,
   description: PropTypes.string,
   buttonText: PropTypes.string,
   nonPrimaryColor: PropTypes.bool,
   textColor: PropTypes.string,
   cancelText: PropTypes.string,
   withoutCancelButton: PropTypes.bool,
   isNeedHelpButton: PropTypes.bool,
   buttonClassName: PropTypes.string,
   className: PropTypes.string,
   secondButtonTitle: PropTypes.string,
   secondButtonClassName: PropTypes.string,
   secondButtonIconName: PropTypes.string,
   secondButtonAction: PropTypes.func,
   secondButtonDisabled: PropTypes.bool,
   buttonIconName: PropTypes.string,
   iconSize: PropTypes.string,
   headerClass: PropTypes.string,
   secondButtonPosition: PropTypes.string,
   primaryButtonClassName: PropTypes.string,
   cancelButtonAction: PropTypes.func,
   withoutCloseButton: PropTypes.bool,
   type: PropTypes.oneOf([
      'action', 'confirm',
   ]),
   buttonBackgroundColor: PropTypes.string,
   withoutConfirmButton: PropTypes.bool,
   secondDescription: PropTypes.string,
   secondDescriptionClassName: PropTypes.string,
   isPreventDeafault: PropTypes.bool,
   otherClassName: PropTypes.string,
   isCloseAction: PropTypes.bool,
   modalWrapperClassName: PropTypes.string,
   titleClassName: PropTypes.string,
   closeOnClickOutside: PropTypes.bool,
   closeBtnClassName: PropTypes.string,
   secondaryButtonClassName: PropTypes.string,
   titleLeading: PropTypes.string,
   descriptionLeading: PropTypes.string,
   maxScreenVersion: PropTypes.string,
   confirmButtonProperties: PropTypes.object,
   isStopPropagation: PropTypes.bool,
   modalClassName: PropTypes.string,
   overrideSecondButtonProps: PropTypes.object,
   headerNewElementId: PropTypes.string,
   confirmButtonHight: PropTypes.string,
   buttonTextClassName: PropTypes.string,
   isTitleHtml: PropTypes.bool,
   imageAsIconSrc: PropTypes.string,
};

ModalWrapper.defaultProps = {
   contentWidth: '375px',
   type: 'action',
   iconSize: 'xl',
   secondDescription: '',
   secondDescriptionClassName: '',
   isPreventDeafault: true,
   otherClassName: '',
   isCloseAction: false,
   closeBtnClassName: '!w-[fit-content]',
   secondaryButtonClassName: '',
   titleLeading: 'leading-[30px]',
   descriptionLeading: 'leading-[24px]',
   maxScreenVersion: 'v1',
   confirmButtonProperties: {},
   modalClassName: '',
   overrideSecondButtonProps: {},
   headerNewElementId: '',
   confirmButtonHight: '9',
   isTitleHtml: false,
};

export default ModalWrapper;
