import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'common/components/elements/inputs/switch';
// import Button from 'common/components/elements/buttons/primary';
// import ConfirmModal from 'common/components/modules/modal-wrapper';
// import Select from 'common/components/modules/select';
// import timezones from 'common/utils/timezones.json';
// import DatePicker from 'common/components/elements/inputs/date-picker';

// const WEEKS_DATA = [
//    { name: 'Everyday', value: 'everyday' },
//    { name: 'Weekdays', value: 'weekdays' },
//    { name: 'Sunday', value: 'sunday' },
//    { name: 'Monday', value: 'monday' },
//    { name: 'Tuesday', value: 'tuesday' },
//    { name: 'Wednesday', value: 'wednesday' },
//    { name: 'Thursday', value: 'thursday' },
//    { name: 'Friday', value: 'friday' },
// ]

const OfflineMode = ({
   // isMobile,
   isOfflineMode,
   updateIsOfflineMode,
   disabled,
   style,
}) => {
   return (
      <div
         style={ style ? { ...style } : {} }
         className='intercom-offline-mode'
      >
         <div className='flex justify-between w-full py-[10px] px-3'>
            <span className='text-major dark:text-major-dark text-base leading-[20px] font-medium intercom-offline-mode'>Offline mode</span>
            <Switch
               name='is_offline_mode'
               onChange={ (name, value) => updateIsOfflineMode({ [name]: value }) }
               checked={ isOfflineMode }
               disabled={ disabled }
            />
         </div>
         {/* <div className='flex flex-col gap-2 items-start'>
            <span className='panel-description text-secondary'>Will automatically show as online at 6:00pm EST, Thurs Sep 1</span>
            <div className='flex h-5'>
               <Button
                  text='Update schedule'
                  textSize='small'
                  textMarginX='0'
                  backgroundColor='transparent'
                  primaryColor
                  padding='0'
               />
            </div>
         </div> */}
         {/* {
            true && (
               <ConfirmModal
                  type='action'
                  icon='clock'
                  iconColor='action'
                  primaryButtonClassName='intercom-request-tracking-code'
                  descriptionColorClass='text-secondary'
                  action={ () => {
                     // intercomMoreInfo(REQUEST_TRACKING_CODE);
                     // this.onClickOpenModal(null, false)
                  } }
                  onCloseModal={ () => {
                     // this.onClickOpenModal(null, false)
                  } }
                  title='Online Auto-Schedule'
                  buttonText='Save'
                  withoutCancelButton
                  contentWidth='742px'
                  isMobile={ isMobile }
                  buttonClassName='mt-8'
               >
                  <div className='flex flex-col gap-7'>
                     <span className='text-center text-base leading-[20px] text-secondary dark:text-secondary-dark'>During these hours, you will be shown as online</span>
                     <div className='flex flex-col gap-4 items-start'>
                        <div className='flex max-w-[320px] w-[320px]'>
                           <Select
                              isMobile={ isMobile }
                              tooltipId='offline-mode-timezone-tooltip'
                              data={ timezones  }
                              name='Select Timezone'
                              // activeValue='ssss'
                              onSelect={ () => {} }
                              className='text-sm rounded-lg border border-divider w-full h-10'
                              maxHeight='320px'
                           />
                        </div>
                        <div className='flex  gap-2'>
                           <div className='flex'>
                              <Select
                                 isMobile={ isMobile }
                                 tooltipId='offline-mode-weeks-date-tooltip'
                                 data={ WEEKS_DATA }
                                 onSelect={ (activeOption) => {} }
                                 name='Select Timezone'
                                 activeValue={ '' }
                                 className='text-sm rounded-lg border border-divider w-full h-10'
                                 maxHeight='320px'
                              />
                           </div>
                           <div className='flex'>
                              <DatePicker
                                 type='time'
                                 labelFontSize='sm'
                                 labelFontWeight='medium'
                                 onChangeDate={ (name, value) => {  } }
                                 name='date_of_birth'
                                 placeholder='Select date mm/dd/yyyy'
                                 // value={ data.date_of_birth }
                                 className='mb-4'
                                 pickerStyle={ {
                                    backgroundColor: '#ffffff',
                                    width: '260px',
                                 } }
                                 typeing
                              />
                           </div>
                           <span>To</span>
                           <div className='flex'>
                              <DatePicker
                                 type='time'
                                 labelFontSize='sm'
                                 labelFontWeight='medium'
                                 onChangeDate={ (name, value) => {  } }
                                 name='date_of_birth'
                                 placeholder='Select date mm/dd/yyyy'
                                 // value={ data.date_of_birth }
                                 className='mb-4'
                                 pickerStyle={ {
                                    backgroundColor: '#ffffff',
                                    width: '260px',
                                 } }
                                 typeing
                              />
                           </div>
                           <div className='flex h-5 w-5'>
                              <Button
                                 iconName='delete'
                                 textMarginX='0'
                                 backgroundColor='transparent'
                                 fontIconColor='error'
                                 // fontIconSize='base'
                                 primaryColor={ false }
                                 padding='0'
                              />
                           </div>
                        </div>

                        <div className='flex h-5'>
                           <Button
                              iconPosition='left'
                              iconName='add'
                              text='Add hours'
                              textSize='small'
                              textMarginX='0 ml-2'
                              backgroundColor='transparent'
                              primaryColor
                              padding='0'
                           />
                        </div>
                        <div className='flex h-5'>
                           <Button
                              iconPosition='left'
                              iconName='pause'
                              text='Pause the scheduler'
                              textSize='small'
                              textMarginX='0 ml-2'
                              backgroundColor='transparent'
                              textColor='error'
                              fontIconColor='error'
                              padding='0'
                           />
                        </div>
                     </div>

                  </div>
               </ConfirmModal>
            )
         } */}
      </div>
   );
};

OfflineMode.propTypes = {
   isOfflineMode: PropTypes.bool,
   updateIsOfflineMode: PropTypes.func,
   disabled: PropTypes.bool,
   style: PropTypes.object,
};

export default OfflineMode;
