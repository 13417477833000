import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss'
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames';
import { createPortal } from 'react-dom';

const RepliesOptionsModal = ({
   isMobile,
   onCloseModal,
   onClickOption,
   optionSelector,
}) => {
   const [animate, setAnimate] = useState('up');
   let timeout = null;
   useEffect(() => {
      return () => {
         if(timeout){
            clearTimeout(timeout)
         }

      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function modalClose(){
      setAnimate('down')
      timeout = setTimeout(() => {
         onCloseModal()
      }, 800)
   }

   function closeOnClickOutside(e) {
      if(!isMobile && !e.target.closest(`.reply-options`)) {
         onCloseModal()
      }
   }


   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, [onCloseModal, isMobile]);

   if(isMobile) {
      return (
         <Modal
            onClose={ modalClose }
            animate={ animate }
         >
            <Options
               onClickOption={ onClickOption }
               isMobile={ isMobile }
            />
         </Modal>
      )
   }
   return (
      <>
         {
            createPortal(
               <>
                  <div
                     className='absolute z-10 bg-panel **right-0 min-w-[200px] top-9'>
                     <Options
                        onClickOption={ onClickOption }
                        isMobile={ isMobile }
                     />
                  </div>

               </>,
               document.querySelector(optionSelector)
            )
         }
      </>
   )
}

const Options  = ({ onClickOption, isMobile }) => {
   return (
      <div
         className={ cx({
            'flex flex-col py-1 h-[88px] border border-solid border-divider rounded-lg shadow-dropdown w-full reply-options': true,
            [`absolute z-50 bg-panel`]: !isMobile,
         }) }
      >
         <div className='h-10'>
            <Button
               text='Edit'
               iconName={ 'pencil' }
               fontIconColor={ 'secondary' }
               textColor={ 'major' }
               onClick={ () => onClickOption('edit') }
               backgroundColor='transparent'
               classNames='!h-10 px-4 hover:bg-hover'
               padding='none'
               iconPosition='left'
               alignment='end'
               textSize='base'
               fontWeight='normal'
               textMarginX='3'
               lineHeight='leading-5'
               textClassName='group-hover:!no-underline'
            />
         </div>
         <div className='h-10'>
            <Button
               text='Delete'
               iconName={ 'delete' }
               fontIconColor={ 'error' }
               textColor={ 'error' }
               onClick={ () => onClickOption('delete') }
               backgroundColor='transparent'
               classNames='!h-10 px-4 hover:bg-hover'
               padding='none'
               iconPosition='left'
               alignment='end'
               textSize='base'
               fontWeight='normal'
               textMarginX='3'
               lineHeight='leading-5'
               textClassName='group-hover:!no-underline'

            />
         </div>
      </div>
   )
}

RepliesOptionsModal.propTypes = {
   isMobile: PropTypes.bool,
   onCloseModal: PropTypes.func,
   onClickOption: PropTypes.func,
   optionSelector: PropTypes.string,
}
RepliesOptionsModal.defaultProps = {
   optionSelector: `.saved-replies-option`,
}
Options.propTypes = {
   onClickOption: PropTypes.func,
   isMobile: PropTypes.bool,
}

export { Options }
export default RepliesOptionsModal;
