import React, { useState, useEffect } from 'react'
import Button from 'common/components/elements/buttons/primary'
import Input from 'common/components/elements/inputs/inputs'
import EmojiPicker from 'common/components/elements/emoji-mart';
import ResponsiveModal from 'common/components/modules/responsive-modal';
import Select from 'common/components/elements-v2/inputs/select';
import CheckBoxLabel from 'common/components/elements/inputs/checkbox-label';
import PropTypes from 'prop-types';
import './style.scss';
import QueryParams from 'admin/utils/QueryParams';
import Icon from 'common/components/elements/icons';

const AddMemberTag = ({
   showCheckbox,
   setShowCheckBox,
   onSaveTag,
   onCloseModal,
   editMode,
   name,
   activeRule,
   icon,
   screenWidth,
   isShowIconAsBadge,
   isAutoTaggingCheck,
   optionValues,
   isButtonLoading,
   errors,
}) => {
   const [newTagName, setNewTagName] = useState(QueryParams.getParam('new-tag-name') || '');
   const [selectTagModal, setSelectTagModal] = useState(false);
   const [activeValue, setActiveValue] = useState(optionValues[0]);
   const [emoji, setEmoji] = useState('');
   const [showIconAsBadge, setShowIconAsBadgeChecked] = useState(false)
   const [autoTagging, setAutoTaggingChecked] = useState(false)
   const [errorMessages, setErrorMessages] = useState({})
   const [showPlaceholder, setShowPlaceholder] = useState(true)




   useEffect(() => {
      setErrorMessages({ ...errors })

      return () => {
         setErrorMessages({ ...errors })
      }
   }, [errors])

   useEffect(() => {

      QueryParams.pushQueryParam('manage', 'add-new-tag')
      setErrorMessages({
         rule: null,
         name: null,
      })

      return () => {

         QueryParams.pushQueryParam('new-tag-name')
         setErrorMessages({
            rule: null,
            name: null,
         })
      }
   }, [])

   useEffect(() => {
      if(!editMode) {
         setShowCheckBox(false)
         if(!!name) {
            onChangeTagName(name)
         }
      } else {
         const active = optionValues.find(el => el.value === activeRule)
         onChangeTagName(name)
         setEmoji(icon)
         setActiveValue(active)
         setShowCheckBox(showCheckbox)
         setShowIconAsBadgeChecked(isShowIconAsBadge)
         setAutoTaggingChecked(isAutoTaggingCheck)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onChangeTagName = (name) => {
      setNewTagName(name);
      setErrorMessages({
         ...errorMessages,
         name: null,
      })
   }

   const addEmoji = (e) => {
      setEmoji(e.native)
      setSelectTagModal(false)
   }

   const onSelectChange = (value) => {
      setActiveValue(value)
      setShowCheckBox(true)
   }

   const handleSave = () => {
      let saveData = {
         emoji: emoji,
         rule: (!!activeValue && autoTagging) ? activeValue.value : null,
         name: newTagName,
         show_icon_as_badge: !!showIconAsBadge,
      }
      onSaveTag(saveData);
      QueryParams.removeQueryParam('manage')
   }

   let isDisabled = newTagName.length === 0 || !emoji || !!(activeValue === null && !!autoTagging);

   const getModalHeight = () => {
      let height = '570px'
      if(!!emoji){
         height = '615px' // 612
      }
      return height
   }

   const height = getModalHeight()
   const top = document.querySelector(".select-wrapper")?.offsetTop;
   const optionsWidth  = document.querySelector(".select-wrapper")?.offsetWidth

   function closeOnClickOutside(e) {
      if(!e.target.closest('.emoji-picker')) {
         setSelectTagModal(false)
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, [selectTagModal]);

   return (
      <>
         <ResponsiveModal
            // headerTitle={ `${ editMode ? 'Edit tag' : 'New member tag' }` }
            headerLeftIconAction={ () => {
               QueryParams.removeQueryParam('manage')
               onCloseModal()
            } }
            onClose={ () => {} }
            headerLeftIconName='close'
            width='375px'
            height={ height }
            footerButtonText='Save changes'
            // isHelpIcon={ !editMode }
            contentClass='p-5 pt-0 pb-6'
            withoutFooter
            isHelpIcon={ false }
            contentPosition={ window?.innerWidth > 700 ? 'responsive' : 'bottom' }
            headerClassName={ 'pl-2 rounded-t-xlg !border-none' }
            modalContentClassName={ `!overflow-visible` }
         >
            <div>
               <div className='flex items-center justify-center' >
                  <div style={ {
                     backgroundColor: `${ window?.skinPrimaryColor }1F`,
                  } } className='h-10 w-10 rounded-full flex items-center justify-center'
                  >
                     <Icon name={ `tag-new` } primaryColor={ `${ window?.skinPrimaryColor }` } size={ `xl` } />
                  </div>
               </div>
               <span className='leading-8 mt-3 mb-4 flex items-center justify-center text-major text-xl font-semibold select-none' style={ {
                  lineHeight: '30px',
               } }>{editMode ? 'Edit tag' : "Create new member tag"}</span>
               <span className='leading-tight text-major font-medium text-sm select-none'>Tag icon</span>
            </div>

            <div className='flex items-center gap-3 mt-2 h-9'>
               {!!emoji && (
                  <span className='text-3xl'>{emoji}</span>
               )}
               <div className={ `${ !!emoji  ? 'replace-button-width' : 'add-button-width' } h-9 relative` }>
                  <Button
                     text={ `${ !!emoji ? 'Replace icon' : 'Add icon' }` }
                     iconName={ `${ !!emoji ? 'replace' : 'add' }` }
                     iconPosition='left'
                     backgroundColor='transparent'
                     textColor=''
                     fontIconColor=''
                     classNames='h-full px-2 h-9 border skin-primary-border skin-primary-text'
                     borderRadius='large'
                     padding='none'
                     textSize='base'
                     onClick={ () => setSelectTagModal(!selectTagModal) }
                     fontIconSize='large'
                  />
                  {selectTagModal  &&  (
                     <div className='emoji-picker'>
                        <EmojiPicker
                           onEmojiSelect={  (e) => addEmoji(e) }
                           style={ {
                              position: 'absolute',
                              top: 40,
                              left: `${ !!emoji ? '-60px' : '-10px' }`,
                              zIndex: 60,
                              width: '352px',
                           } }
                        />
                     </div>
                  )}
               </div>

            </div>
            <div className='mt-6'>
               <span className='leading-tight text-major font-medium text-sm'>Name</span>
               <Input
                  size='sm'
                  placeholder='New tag name'
                  name='tagname'
                  className='mt-1'
                  onChange={ (name, value) => {
                     if(value.length > 25){
                        return
                     }
                     onChangeTagName(value)
                  } }
                  onInputFocus={ () => {
                     setErrorMessages({
                        ...errorMessages,
                        name: null,
                     })
                  } }
                  value={ newTagName }
                  description={  errorMessages?.name?.[0] ? null : `${ newTagName ? newTagName.length : 0 } of 25 characters` }
                  errorMessage={ errorMessages?.name?.[0] }
                  height='10'
                  descriptionPosition={ 'right' }
                  descriptionFontWeight={ 'normal' }
                  descriptionMarginBottom={ '0' }
               />
            </div>
            <div className='mt-4'>
               <div className='h-5 flex mt-4'>
                  <CheckBoxLabel
                     name='auto_tagging'
                     padding='0'
                     margin='3'
                     // disabled={ editMode }
                     className='items-center h-5'
                     checked={ autoTagging }
                     onChange={ () => {
                        setAutoTaggingChecked(!autoTagging)
                        if(autoTagging === false) {
                           setActiveValue(optionValues[0])
                           setErrorMessages({
                              ...errorMessages,
                              rule: null,
                           })
                        } else {
                           setActiveValue(null)
                        }

                     } }
                     textSize='xl'
                     checkboxSize='5'
                  >
                     <p className={ `leading-5
                      text-major
                      text-sm text-center h-full` }>Auto-tagging
                        <span className='font-normal text-xs text-placeholder dark:text-placeholder-dark'> (optional)</span>
                     </p>
                  </CheckBoxLabel>
               </div>
               <p className={ `text-major leading-5 mt-3 text-sm  pl-1 select-none` }>Automatically tag members based on rules</p>
               <div className='relative select-wrapper'>
                  <Select
                     className={ `mt-2 !h-10 leading-5 text-major ${ autoTagging && errorMessages.rule?.[0] && 'border-error' } ${ autoTagging && 'hover:bg-hover' }` }
                     removeCheckIcon
                     data={ optionValues }
                     isWideActive
                     isFixed
                     style={ {
                        top: `${ screenWidth < 770 ?  top - 221 : top + 50 }px`,
                        width: `${ optionsWidth }px`,
                        zIndex: 999999,
                     } }
                     activeItem={ (showPlaceholder && !editMode && !autoTagging) ? { display_name: 'Select rule' } :  activeValue }
                     withoutIcon
                     handleChange={ (item) => {
                        onSelectChange(item)
                        setShowPlaceholder(false)
                        setErrorMessages({
                           ...errorMessages,
                           rule: null,
                        })
                     } }
                     withRadioButtons
                     disabled={ !autoTagging }
                     removeOptionsOverflow
                  />
                  { !!emoji && <p className='w-full h-[18px] flex justify-end leading-[18px] mt-[6px] text-error text-xs'>{autoTagging && errorMessages?.rule && errorMessages?.rule?.[0]}</p>}
               </div>

            </div>
            {
               !!emoji && (
                  <>
                     <div className='h-5'>
                        <CheckBoxLabel
                           name='show_icon_as_badge'
                           label={ `Show tag icon as badge on member's picture` }
                           padding='0'
                           margin='3'
                           className='leading-5 select-none h-5'
                           checked={ showIconAsBadge }
                           onChange={ () => setShowIconAsBadgeChecked(!showIconAsBadge) }
                           textColor='major'
                           checkboxSize='5'
                        />
                     </div>
                     {/* <p className={ `text-secondary leading-5 mt-1 text-sm  pl-8 select-none` }>This will show on your site to other members</p> */}
                  </>
               )
            }
            <div className='mt-8'>
               <Button
                  text='Save'
                  disabled={ isDisabled || isButtonLoading }
                  primaryColor
                  borderRadius='large'
                  onClick={ handleSave }
                  isLoading={ isButtonLoading }
                  classNames='h-9 leading-5'
               />
            </div>
         </ResponsiveModal>
         {/* {
            selectTagModal && (
               <ResponsiveModal
                  withoutFooter
                  headerTitle={ `${ !!emoji ? 'Replace icon' : 'Add icon' }` }
                  headerLeftIconName='arrow-left'
                  headerLeftIconAction={ () => setSelectTagModal(false) }
                  onClose={ () => setSelectTagModal(false) }
                  isCloseAction={ false }
                  isHelpIcon={ !editMode }
                  contentClass='p-4'
                  width='375px'
                  height={ `${ showCheckbox ? '513px' : '472px' }` }
               >
                  <span className='leading-tight text-major font-medium'>Tag icons</span>
                  <div
                     className='w-full relative mt-2'
                     style={ {
                        height: 'calc(100% - 50px)',
                     } }
                  >
                     <Picker
                        onSelect={ e => addEmoji(e) }
                        showPreview={ false }
                        sheetSize={ 32 }
                        set='messenger'
                        style={ {
                           position: 'absolute',
                           width: '100%',
                           height: getHeight(),
                           maxWidth: '100%',
                           top: '0px',
                           right: 0,
                           left: 0,
                           zIndex: 60,
                           borderRadius: '16px',
                        } }
                     />
                  </div>
               </ResponsiveModal>
            )
         } */}
      </>

   )
}

AddMemberTag.propTypes = {
   showCheckbox: PropTypes.bool,
   setShowCheckBox: PropTypes.func,
   onSaveTag: PropTypes.func,
   onCloseModal: PropTypes.func,
   editMode: PropTypes.bool,
   icon: PropTypes.string,
   name: PropTypes.string,
   activeRule: PropTypes.string,
   screenWidth: PropTypes.number,
   isShowIconAsBadge: PropTypes.bool,
   isAutoTaggingCheck: PropTypes.bool,
   optionValues: PropTypes.array,
   isButtonLoading: PropTypes.bool,
   errors: PropTypes.object,
}

export default AddMemberTag
