import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Filter from 'common/components/responsive/filter';
import cx from 'classnames'
import Button from 'common/components/elements/buttons/primary';

const INITIAL_FILTERS = {
   sortBy: { name: '', value: 'date_added:desc' },
   chatContentStatus: { name: null,  value: [] },
}

const chatContentStatuses = [
   { name: 'Sent', value: 'sent' },
   { name: 'Sent locked', value: 'locked' },
   { name: 'Unlocked', value: 'unlocked' },
   { name: 'Unsent', value: 'unsent' },
]

const allSorts = [
   { name: 'Newest', value: 'date_added:desc' },
   { name: 'Oldest', value: 'date_added:asc' },
]

const VaultFilters = ({
   contentType,
   screenWidth,
   desktopType,
   onFilterChange,
   filtersIntoModal,
   onClearAllFilters,
   type,
   activeFilters = {},
}) => {

   const [filters, setFilters] = useState(activeFilters);
   const [emptyFilters, setEmptyFilters] = useState(true);

   function onSimpleFilter(value, name){
      setFilters({
         ...filters,
         sortBy: { name, value },
      })
      onFilterChange(value)
   }

   function onClearFilter(isSimple, type, defaultValue){
      let emptyValue = isSimple ? {} : [];
      if(defaultValue) {
         emptyValue = defaultValue
      }
      if(type === 'sortBy') {
         emptyValue = {
            name: '',
            value: allSorts[0].value,
         }
      }

      setFilters({
         ...filters,
         [type]: emptyValue,
      })
      onFilterChange(isSimple ? '' : [], type)
   }

   function onMultiplFilter(value, name, type, bool){
      let data = []
      if(filters[type] && filters[type].value && Array.isArray(filters[type].value)) {
         data = filters[type].value
      }
      if(data && Array.isArray(data) && data.includes(value)) {
         data = data.filter(i => i !== value)
      } else {
         data = [...data, value]
      }
      console.log({
         ...filters,
         [type]: {
            name: data.length === 0 ? null : `${ data.length } Selected`,
            value: data,
         },
      });
      setFilters({
         ...filters,
         [type]: {
            name: data.length === 0 ? null : `${ data.length } Selected`,
            value: data,
         },
      })
      if(bool) {
         onFilterChange(data, type)
      }
   }

   const onClickClearAll = () => {
      setFilters({ ...INITIAL_FILTERS })
      onClearAllFilters()
   }

   useEffect(() => {
      setEmptyFilters(!filters?.chatContentStatus?.value?.length && filters?.sortBy?.value === 'date_added:desc')
   }, [filters])

   useEffect(() => {
      setFilters({ ...activeFilters })
      setFilters(getDefaultFilters())
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


   const getDefaultFilters = () => {
      let sortBy = { name: '', value: 'date_added:desc' }
      let chatContentStatus = { name: null,  value: [] }

      if(!!filters?.chatContentStatus?.length){
         let arr = filters?.chatContentStatus?.split(',')
         chatContentStatus = { name: `${ arr.length } Selected`, value: arr }
      }
      if(!!filters?.sortBy){
         sortBy = { name: filters?.sortBy === 'date_added:desc' ? '' : 'Oldest', value: filters?.sortBy?.value }
      }

      return { sortBy, chatContentStatus }
   }

   return (
      <div
         className={ cx({
            'vault-search-sorting flex w-full': true,
            '!px-6': filtersIntoModal,
            'pb-6': filtersIntoModal && screenWidth > 1025,
         }) }
         id='photo_vaults-filter-content'
      >
         <div className='w-full z-20 flex justify-between gap-3 flex-wrap'>
            {
               contentType === 'chat' &&
                  <div className='flex gap-3 flex-wrap'>
                     <Filter
                        isMultiplSelect
                        activeSelectedValue={ filters.chatContentStatus }
                        name='Chat status'
                        type='filter'
                        data={ chatContentStatuses }
                        desktopType={ desktopType }
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'chatContentStatus', true) }
                        onClickShowResult={ () => {} }
                        onClickClear={ () => {
                           onClearFilter(false, 'chatContentStatus')
                        } }
                        height='10'
                        contentStyles={ {
                           left: '0px',
                           top: '44px',
                        } }
                        filterClassName='flex-none'
                        className='intercom-chat-status-filter'
                     />
                     {
                        !emptyFilters &&
                        <div className='h-10 mx-2 whitespace-nowrap inline-block'>
                           <Button
                              text='Clear all'
                              textColor='secondary'
                              backgroundColor='transparent'
                              textSize='base'
                              onClick={ onClickClearAll }
                              classNames='h-full px-2 flex items-center'
                              padding='none'
                              iconName='cancel'
                              fontIconColor='secondary'
                              fontIconSize='large'
                              iconPosition='left'
                           />
                        </div>
                     }
                  </div>
            }
            <Filter
               name='Sorting'
               data={ allSorts }
               desktopType={ desktopType }
               className=''
               onFilterChange={ (value, name) => onSimpleFilter(value, name) }
               activeSelectedValue={ {
                  name: filters?.sortBy?.value === 'date_added:desc' ? '' : filters?.sortBy?.name,
                  value: filters?.sortBy?.value,
               } }
               height='10'
               contentStyles={ {
                  right: '0',
                  top: '44px',
                  width: '200px',
                  left: contentType !== 'chat' ? '4px' : '',
               } }
               filterClassName='flex-none'
            />
         </div>
      </div>
   )
}

VaultFilters.propTypes = {
   desktopType: PropTypes.string,
   screenWidth: PropTypes.number,
   contentType: PropTypes.string,
   onFilterChange: PropTypes.func,
   onClearAllFilters: PropTypes.func,
   filtersIntoModal: PropTypes.bool,
   type: PropTypes.string,
   activeFilters: PropTypes.object,
}

export default VaultFilters
