import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactQuill, { Quill } from 'react-quill';
import AutoLinks from 'quill-auto-links';
import CustomToolbar from './customToolbar';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import TextArea from 'common/components/elements/inputs/textarea';
import ReactDOMServer from 'react-dom/server';
import { toolbarMMSIconsName, ToolbarIcon } from './modules';

import './style.scss';
const replacedToolbarIcons = {
   background: 'text-background',
   color: 'text-color',
}
const SizeStyle = ['4px', '6px', '8px', '10px', '12px', '14px', '16px', '18px', '24px', '36px', '48px', '54px'];
Quill.register("modules/autoLinks", AutoLinks);
const Parchment = Quill.import('parchment');
const Size = Quill.import('attributors/style/size');
const FormatsSize = Quill.import('formats/size');
const FontAttributor = Quill.import('attributors/style/font');
const Font = Quill.import('formats/font');
const Icons = Quill.import('ui/icons');

const CustomSpan = Quill.import('blots/inline');
const Block = Quill.import('blots/block');

class BlockBlot extends Block {
   static blotName = 'p';
   static tagName = 'p';
   static create(value) {
      let node = super.create();
      if(value?.class){
         node.setAttribute('class', value.class || null);
      }
      // if(value?.style){
      //    node.setAttribute('style', value.style || null);
      // }
      return node;
   }
   static formats(node) {
      return { class: node.getAttribute('class') };
   }
   format(name, value) {
      if(name === 'p' && value?.class) {
         if(value?.class){
            this.domNode.setAttribute('class', value.class);
         }
         // if(value?.style){
         //    this.domNode.setAttribute('style', value.style);
         // }
      } else {
         super.format(name, value);
      }
   }
}
class SpanBlot extends CustomSpan {
   static blotName = 'span';
   static tagName = 'span';
   static create(value) {
      let node = super.create();
      if(value){
         node.setAttribute('class', value || null);
      }
      return node;
   }
   static formats(node) {
      return node.getAttribute('class');
   }
   format(name, value) {
      if(name === 'span' && value) {
         if(value){
            this.domNode.setAttribute('class', value);
         }
      } else {
         super.format(name, value);
      }
   }
}
const BlockEmbed = Quill.import('blots/block/embed');

class VideoBlot extends BlockEmbed {
   static create(url) {
      let node = super.create();
      // Set default or dynamic attributes
      const width = this.customWidth || '560';  // Default width or user-provided
      const height = this.customHeight || '315';  // Default height or user-provided

      node.setAttribute('src', url);
      node.setAttribute('frameborder', '0');
      node.setAttribute('allowfullscreen', true);
      node.setAttribute('width', width);  // Set dynamic width
      node.setAttribute('height', height);  // Set dynamic height
      node.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');
      node.setAttribute('referrerpolicy', 'strict-origin-when-cross-origin');
      node.setAttribute('title', 'YouTube video player');

      return node;
   }
   static value(node) {
      return node.getAttribute('src');
   }

   // Method to set custom width and height
   static setDefaultAttributes(attributes) {
      this.customWidth = attributes?.width;
      this.customHeight = attributes?.height;
   }
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';
VideoBlot.className = 'ql-video';

Quill.register(VideoBlot, true);
Quill.register(BlockBlot, true);
Quill.register(SpanBlot, true);


const QuillEditor = ({
   height,
   isShowVariableButton,
   variableList,
   handleEditorBlur,
   handleEditorFocus,
   isShowEmoji,
   content,
   lightDarkMode,
   placeholder,
   toolbar,
   fontsList,
   handleEditorChange,
   editorRef,
   handleonKeyPress,
   readOnly,
   id,
   showSourceCode,
   scrollingContainer,
   backContent,
   isMobile,
   bounds,
   defaultValue,
   isInited,
   toolbarRef,
})  => {

   Object.keys(Icons).forEach(icon => {

      if(replacedToolbarIcons?.[icon]){
         Icons[icon] = ReactDOMServer.renderToString(<ToolbarIcon name={ replacedToolbarIcons[icon] } />);
      } else if(toolbarMMSIconsName?.[icon]){
         Icons[icon] =  null;
      } else if(icon === 'align'){
         Icons['align'] = {
            '': ReactDOMServer.renderToString(<ToolbarIcon name='text-left' />),
            center: ReactDOMServer.renderToString(<ToolbarIcon name='text-center' />),
            right: ReactDOMServer.renderToString(<ToolbarIcon name='text-right' />),
            justify: ReactDOMServer.renderToString(<ToolbarIcon name='text-justify' />),
         }
      }
   });
   const [sourceCodeValue, setSourceCodeValue] = useState('');


   const [cursorPosition, setCursorPosition] = useState(null);
   const [openSourceCodeModal, setOpenSourceCodeModal] = useState(false);

   let lineheightConfig = {
      scope: Parchment.Scope.BLOCK,
      whitelist: ['1', '1_1', '1_2', '1_3', '1_4', '2_5', '2'],
   };
   let LineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineheightConfig);
   Quill.register(LineHeightClass, true);

   Size.whitelist = SizeStyle;
   FormatsSize.whitelist = SizeStyle;
   Quill.register(Size, true);
   Quill.register(FormatsSize, true);

   FontAttributor.whitelist = fontsList;
   Font.whitelist = fontsList;
   Quill.register(Font, true);
   Quill.register(FontAttributor, true);

   const genearteToolbar = () => {
      // clean remove format
      let toolbarOptions = [];

      for(const tool of toolbar) {
         switch (tool) {
            case 'fontsizeselect':{
               toolbarOptions = [
                  ...toolbarOptions, { size: SizeStyle },
               ]
               break;
            }
            case 'alignleft':{
               toolbarOptions = [
                  ...toolbarOptions,  { align: '' },
               ]
               break;
            }
            case 'align':{
               toolbarOptions = [
                  ...toolbarOptions,  { align: ['', 'center', 'right', 'justify'] },
               ]
               break;
            }
            case 'aligncenter':{
               toolbarOptions = [
                  ...toolbarOptions,  { align: 'center' },
               ]
               break;
            }
            case 'alignright':{
               toolbarOptions = [
                  ...toolbarOptions,  { align: 'right' },
               ]
               break;
            }
            case 'alignjustify': {
               toolbarOptions = [
                  ...toolbarOptions,  { align: 'justify' },
               ]
               break;
            }
            case 'bullist': {
               toolbarOptions = [
                  ...toolbarOptions, { list: 'bullet' },
               ]
               break;
            }
            case 'numlist': {
               toolbarOptions = [
                  ...toolbarOptions, { list: 'ordered' },
               ]
               break;
            }
            case 'fontselect':{
               toolbarOptions = [
                  ...toolbarOptions,  { font: fontsList },
               ]
               break;
            }
            case 'indent':{
               toolbarOptions = [
                  ...toolbarOptions,  { indent: '+1' },
               ]
               break;
            }
            case 'outdent':{
               toolbarOptions = [
                  ...toolbarOptions,  { indent: '-1' },
               ]
               break;
            }
            case 'forecolor': {
               toolbarOptions = [
                  ...toolbarOptions, { color: [] },
               ]
               break;
            }
            case 'backcolor': {
               toolbarOptions = [
                  ...toolbarOptions, { background: [] },
               ]
               break;
            }
            case 'lineheight': {
               toolbarOptions = [
                  ...toolbarOptions, { lineheight: lineheightConfig.whitelist },
               ]
               break;
            }
            default:{
               toolbarOptions = [...toolbarOptions, tool];
               break;
            }
         }

      }
      if(isShowEmoji){
         toolbarOptions = [...toolbarOptions, 'emoji']
      }
      if(isShowVariableButton){
         toolbarOptions = [...toolbarOptions, 'variable']
      }
      return toolbarOptions
   }
   const tools =  genearteToolbar();

   const undoChange = () => {
      editorRef.current.getEditor().history.undo();
   };

   const redoChange = () => {
      editorRef.current.getEditor().history.redo();
   };
   // const handleItalicClick = () => {
   //    const quill = editorRef.current.getEditor();
   //    quill.format('italic', !quill.getFormat().italic);
   // };
   // function showColorPicker(value) {
   //    if(value === 'color-picker') {
   //       var picker = document.getElementById('color-picker');
   //       if(!picker) {
   //          picker = document.createElement('input');
   //          picker.id = 'color-picker';
   //          picker.type = 'color';
   //          picker.style.display = 'none';
   //          picker.value = '#FF0000';
   //          document.body.appendChild(picker);

   //          picker.addEventListener('change', function() {
   //             editorRef.current.getEditor().format('color', picker.value);
   //          }, false);
   //       }
   //       picker.click();
   //    } else {
   //       editorRef.current.getEditor().format('color', value);
   //    }
   // }

   // var toolbarasd =  editorRef?.current?.getEditor()?.getModule('toolbar');
   // toolbarasd?.addHandler('color', showColorPicker);

   const insertText = (text, bool) =>  {
      if(!text) return;
      if(editorRef.current) {
         const quill = editorRef.current.getEditor();
         const savedIndex = quill.selection?.savedRange?.index || 0
         if(bool){
            quill.insertText(savedIndex, text, 'user');
            quill.formatText(savedIndex, text.length, 'span', 'editor-variable', 'user');
            quill.setSelection(savedIndex + text.length, 0);
            quill.format('span', null);
            return
         }
         quill.insertText(savedIndex, text, 'user');
         quill.setSelection(savedIndex + text.length, 0);
      }
   }
   const formats = [
      // 'background', 'color',
      'bold', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', //Inline
      'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', //Block
      'image', 'video', // Embeds `formula`
      'undo', 'redo',
      // 'lineheight',
      'span',
      'p',
   ]

   return (
      <>
         {
            openSourceCodeModal && (
               <ConfirmModal
                  type='confirm'
                  withoutCloseButton={ false }
                  onCloseModal={ () => {
                     setOpenSourceCodeModal(false);
                  }  }
                  cancelText='Cancel'
                  contentWidth='80%'
                  // isMobile={ screenWidth < 1025 }
                  buttonText='Save'
                  action={ () => {
                     if(editorRef.current){
                        const delta = editorRef.current.editor.clipboard.convert(sourceCodeValue);
                        const options = delta?.ops || [];
                        const video = options.find(option => option?.insert && option.insert?.video);
                        if(video?.attributes){
                           VideoBlot.setDefaultAttributes(video.attributes);
                        }
                        editorRef.current.editor.setContents(delta, 'user');
                     }
                     setOpenSourceCodeModal(false)
                  } }
                  buttonClassName='mt-8'
                  disabled={ !sourceCodeValue }
               >
                  <div className='w-full h-[50vh]'>
                     <TextArea
                        value={ sourceCodeValue }
                        onChange={ (name, value) => setSourceCodeValue(value) }
                        height='full'
                        className='!h-full'
                     />
                  </div>

               </ConfirmModal>

            )
         }
         {
            Boolean(tools?.length) && (
               <CustomToolbar
                  fontsList={ fontsList }
                  sizeList={ SizeStyle }
                  toolbar={ tools }
                  undoChange={ undoChange }
                  redoChange={ redoChange }
                  editor={ editorRef.current?.getEditor() }
                  isShowVariableButton={ isShowVariableButton }
                  insertText={ insertText }
                  variableList={ variableList }
                  cursorPosition={ cursorPosition }
                  setCursorPosition={ setCursorPosition }
                  id={ `toolbar_${ id }` }
                  setOpenSourceCodeModal={ () => {
                     setOpenSourceCodeModal(!openSourceCodeModal)
                  } }
                  showSourceCode={ showSourceCode }
                  toolbarMMSIconsName={ toolbarMMSIconsName }
                  lineheightList={ lineheightConfig.whitelist }
                  isMobile={ isMobile }
                  toolbarRef={ toolbarRef }
                  isInited={ isInited }
               />
            )
         }
         {
            !isInited && (
               <div
                  style={ {
                     minHeight: height,
                  } }
                  className='tool-loading-card'
               />
            )
         }
         {
            isInited && (
               <ReactQuill
                  style={ {
                     lightDarkMode,
                     minHeight: height,
                  } }
                  modules={ {
                     toolbar: tools?.length ? {
                        container: `#toolbar_${ id }`,
                        // container: genearteToolbar(),
                        // handlers: {
                        //    // 'bold': handleBoldClick,
                        //    // 'italic': handleItalicClick,
                        // },
                        // container: genearteToolbar(),
                     } : {
                        container: genearteToolbar(),
                     },
                     history: {
                        delay: 1000,
                        maxStack: 100,
                        userOnly: false,
                     },
                     autoLinks: {
                        paste: true,
                        type: true,
                     },
                     clipboard: {
                        matchVisual: false, // Prevent sanitizing
                     },
                  } }
                  formats={ formats }
                  value={ content }
                  placeholder={ placeholder }
                  ref={ ref =>  editorRef.current = ref }
                  theme='snow'
                  className=' ql-text-major'
                  onBlur={ (range, type, editor) => {
                     const quill = editorRef.current.getEditor();
                     let cursorPosition = quill.selection?.savedRange?.index || 0
                     setCursorPosition(cursorPosition)
                     if(handleEditorBlur){
                        handleEditorBlur(range, type, editor)
                     }
                  } }
                  onFocus={ (range, type, editor) => {
                     if(handleEditorFocus){
                        handleEditorFocus(range, type, editor)
                     }
                  } }
                  onChange={ (html, delta, source, editor) => {
                     if('user' === source){
                        handleEditorChange(html, editor, source, delta)
                        setSourceCodeValue(backContent(editor, delta))
                     }

                  } }
                  // onKeyPress={ (e) => console.log(e) }
                  onKeyDown={ handleonKeyPress }
                  readOnly={ readOnly }
                  scrollingContainer={ scrollingContainer }
                  bounds={ bounds }
                  id={ `mms-quill-editor-${ id }` }
                  defaultValue={ defaultValue }
               />
            )
         }
      </>
   )
}

QuillEditor.propTypes = {
   content: PropTypes.string,
   height: PropTypes.any,
   isShowVariableButton: PropTypes.bool,
   variableList: PropTypes.array,
   handleEditorBlur: PropTypes.func,
   handleEditorFocus: PropTypes.func,
   isShowEmoji: PropTypes.bool,
   lightDarkMode: PropTypes.object,
   placeholder: PropTypes.string,
   toolbar: PropTypes.array,
   fontsList: PropTypes.array,
   handleEditorChange: PropTypes.func,
   handleonKeyPress: PropTypes.func,
   editorRef: PropTypes.any,
   readOnly: PropTypes.bool,
   showSourceCode: PropTypes.bool,
   isMobile: PropTypes.bool,
   id: PropTypes.string,
   scrollingContainer: PropTypes.string,
   bounds: PropTypes.string,
   backContent: PropTypes.func,
   defaultValue: PropTypes.string,
   isInited: PropTypes.bool,
   toolbarRef: PropTypes.any,
}

export default QuillEditor

