import React, { useState, useEffect } from 'react'
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import PropTypes from 'prop-types';
import './style.scss';
import QueryParams from 'admin/utils/QueryParams';
import PageLoader from 'common/components/modules/page-loading';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';

const OptionsModal = ({
   isMobile,
   onCloseModal,
   editUsername,
   bottomPosition,
   viewProfile,
   goToPrivateChat,
   giveFreeMemberShip,
   memberStatus,
   setMemberOptionModal,
   isMuted,
   isBlocked,
   id,
   goToProfile,
   insideChat,
   openCustomTagModal,
   rightPosition,
   isDisabledNotifications,
   optionButtonRef,
   memberUsername,
   isLoading,
   tooltipId,
   goToDetails,
   onOpenEditNickname,
   memberData,
}) => {
   const [animate, setAnimate] = useState('up');
   let timeout = null;
   useEffect(() => {
      return () => {
         if(timeout){
            clearTimeout(timeout)
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   function modalClose(){
      setAnimate('down')
      timeout = setTimeout(() => {
         onCloseModal()
      }, 800)
   }

   const closeModalWithTimeout = () => {
      timeout = setTimeout(() => {
         onCloseModal()
      }, 100)
   }


   function closeOnClickOutside(e) {
      if(!isMobile && !e.target.closest(`.member-${ id }`) && !e.target.closest(`#member_options_${ id }`) &&  !e.target.closest(`#member_options_${ id }_tooltip`)) {
         onCloseModal()
      }
   }
   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };   // eslint-disable-next-line
   }, [onCloseModal, isMobile]);

   const getOptionPositionStyles = () => {
      let styles = {
         bottom: bottomPosition,
         right: rightPosition,
      }
      if(optionButtonRef?.current?.getBoundingClientRect()?.left < 435) {
         styles = {
            bottom: bottomPosition,
            left: 0,
         }
      }
      return styles;
   }
   if(tooltipId && !isMobile){
      return (
         <>
            {
               createPortal(
                  <Tooltip
                     id={ tooltipId }
                     content=''
                     events={ ['click'] }
                     className='chat-tooltip shadow dark:shadow border-divider  dark:border-divider'
                     place='bottom'
                     clickable
                     variant='light'
                     isOpen={ true }
                     noArrow
                     openOnClick
                  >
                     <MemberOptions
                        closeModalWithTimeout={ closeModalWithTimeout }
                        giveFreeMembership={ giveFreeMemberShip }
                        editUsername={ editUsername }
                        goToProfile={ goToProfile }
                        openCustomTagModal={ openCustomTagModal }
                        setMemberOptionModal={ setMemberOptionModal }
                        insideChat={ insideChat }
                        isMobile={ isMobile }
                        id={ id }
                        goToPrivateChat={ goToPrivateChat }
                        viewProfile={ viewProfile }
                        memberStatus={ memberStatus }
                        isMuted={ isMuted }
                        isDisabledNotifications={ isDisabledNotifications }
                        isBlocked={ isBlocked }
                        memberUsername={ memberUsername }
                        isLoading={ isLoading }
                        goToDetails={ goToDetails }
                        onOpenEditNickname={ onOpenEditNickname }
                        memberData={ memberData }
                     />
                  </Tooltip >,
                  document.getElementById('tooltips')
               )
            }
         </>
      )
   }
   if(isMobile) {
      return (
         <Modal
            onClose={ modalClose }
            animate={ animate }
         >
            <MemberOptions
               closeModalWithTimeout={ closeModalWithTimeout }
               giveFreeMembership={ giveFreeMemberShip }
               editUsername={ editUsername }
               goToProfile={ goToProfile }
               openCustomTagModal={ openCustomTagModal }
               setMemberOptionModal={ setMemberOptionModal }
               insideChat={ insideChat }
               isMobile={ isMobile }
               id={ id }
               goToPrivateChat={ goToPrivateChat }
               viewProfile={ viewProfile }
               memberStatus={ memberStatus }
               isMuted={ isMuted }
               isDisabledNotifications={ isDisabledNotifications }
               isBlocked={ isBlocked }
               memberUsername={ memberUsername }
               goToDetails={ goToDetails }
               onOpenEditNickname={ onOpenEditNickname }
               memberData={ memberData }
            />
         </Modal>
      )
   } else {
      return (
         <div
            style={ { width: '342px', ...getOptionPositionStyles() } }
            className='absolute z-30 bottom-0'
         >
            <MemberOptions
               closeModalWithTimeout={ closeModalWithTimeout }
               giveFreeMembership={ giveFreeMemberShip }
               editUsername={ editUsername }
               goToProfile={ goToProfile }
               openCustomTagModal={ openCustomTagModal }
               setMemberOptionModal={ setMemberOptionModal }
               insideChat={ insideChat }
               isMobile={ isMobile }
               id={ id }
               goToPrivateChat={ goToPrivateChat }
               viewProfile={ viewProfile }
               memberStatus={ memberStatus }
               isMuted={ isMuted }
               isDisabledNotifications={ isDisabledNotifications }
               isBlocked={ isBlocked }
               memberUsername={ memberUsername }
               isLoading={ isLoading }
               goToDetails={ goToDetails }
               onOpenEditNickname={ onOpenEditNickname }
               memberData={ memberData }
            />
         </div>
      )
   }
}

export const MemberOptions = ({
   closeModalWithTimeout,
   giveFreeMembership,
   editUsername,
   goToProfile,
   openCustomTagModal,
   setMemberOptionModal,
   insideChat,
   id,
   goToPrivateChat,
   viewProfile,
   memberStatus,
   isMuted,
   isDisabledNotifications,
   isBlocked,
   isMobile,
   memberUsername,
   isLoading,
   goToDetails,
   onOpenEditNickname,
   memberData,
}) => {
   const actions = (e, type) => {
      e.stopPropagation();
      !!closeModalWithTimeout && closeModalWithTimeout();
      switch (type){
         case 'give_free_membership' :
            giveFreeMembership()
            break;
         case 'edit_username' :
            editUsername();
            break;
         case 'view_profile' :
            goToDetails()
            // goToProfile();
            break;
         case 'add_tag' :
            openCustomTagModal();
            break;
         case 'edit_nickname' :
            onOpenEditNickname(id);
            break;
         default :
            setMemberOptionModal(type)
      }
   }
   let unableToGiveFreeMembership = memberStatus === 'active' || memberStatus === 'cancelled'
   if(isLoading) {
      return (
         <div className={ `h-[330px] member-options  shadow dark:shadow-dark member-${ id } flex flex-col w-full bg-panel py-1 items-center justify-between ${ isMobile ? '' : 'border rounded-lg' }` }>
            <PageLoader withLoader />
         </div>
      )
   }
   return (
      <div className={ `member-options shadow dark:shadow-dark member-${ id } flex flex-col w-full bg-panel py-1 items-center justify-between ${ isMobile ? '' : 'border rounded-lg' }` }>
         {
            !!goToDetails && (
               <div className='member-options-item flex w-full h-10 items-center justify-between'>
                  <Button
                     textMarginX='0'
                     text='Go to member profile'
                     textSize='small'
                     textPosition='left'
                     textColor='major'
                     classNames='h-full w-full gap-3 px-4'
                     iconName='go-to'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ e => actions(e, 'view_profile') }
                     fontWeight='normal'
                  />
               </div>
            )
         }
         {
            !insideChat && (
               <div className='member-options-item flex w-full h-10 items-center justify-between'>
                  <Button
                     textMarginX='0'
                     text='Message'
                     textSize='small'
                     textColor='major'
                     classNames='h-full w-full gap-3 px-4'
                     textPosition='left'
                     iconName='chat'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        !!closeModalWithTimeout && closeModalWithTimeout();
                        goToPrivateChat()
                     } }
                     fontWeight={ 'normal' }
                     lineHeight='leading-5'
                  />
               </div>
            )
         }
         {
            memberStatus !== 'free' &&
            //  memberStatus !== 'active' && memberStatus !== 'cancelled' &&
            (
               <div className='member-options-item flex w-full h-10 items-center justify-between'
                  data-tooltip-content={ unableToGiveFreeMembership ? `You can't give a complimentary membership to an active/cancelled member` : null }
                  data-tooltip-id={ unableToGiveFreeMembership ? 'ams-bottom-tooltip' : null }
               >
                  <Button
                     textMarginX='0'
                     text='Give complimentary membership'
                     textSize='small'
                     textPosition='left'
                     textColor='major'
                     classNames={ `h-full w-full gap-3 px-4 ${ unableToGiveFreeMembership ? 'opacity-50 hover:cursor-default' : '' }` }
                     iconName='present'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ e => {
                        e.stopPropagation()
                        e.preventDefault()
                        !unableToGiveFreeMembership && actions(e, 'give_free_membership')
                     } }
                     fontWeight={ 'normal' }
                     lineHeight='leading-5'
                  />
               </div>
            )}
         {
            memberStatus === 'free' && (
               <div className='member-options-item flex w-full h-10 items-center justify-between'  >
                  <Button
                     textMarginX='0'
                     text='Expire complimentary membership'
                     textPosition='left'
                     textSize='small'
                     textColor='major'
                     classNames='h-full w-full gap-3 px-4'
                     iconName='membership'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ e => {
                        actions(e, 'expire_free_membership')
                        e.stopPropagation()
                        e.preventDefault()
                     } }
                     fontWeight={ 'normal' }
                     lineHeight='leading-5'
                  />
               </div>
            )
         }
         {/* <div className='member-options-item flex w-full h-10 items-center justify-between'>
            <Button
               textMarginX='0'
               text='Edit username'
               textPosition='left'
               textSize='small'
               textColor='major'
               classNames='h-full w-full gap-3 px-4'
               iconName='pencil'
               fontIconColor='secondary'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ e => {
                  e.stopPropagation()
                  e.preventDefault()
                  actions(e, 'edit_username')
                  QueryParams.addQueryParam('manage', 'edit-username')
               } }
               fontWeight={ 'normal' }
               lineHeight='leading-5'
            />
         </div> */}
         {
            !!onOpenEditNickname &&
               <div className='member-options-item flex w-full h-10 items-center justify-between'>
                  <Button
                     textMarginX='0'
                     text={ ` ${ memberData?.nickname ? `Edit` : `Add` } nickname` }
                     textPosition='left'
                     textSize='small'
                     textColor='major'
                     classNames='h-full w-full gap-3 px-4 intercom-member-nickname'
                     iconName='pencil'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ e => {
                        e.stopPropagation()
                        e.preventDefault()
                        actions(e, 'edit_nickname')
                        QueryParams.addQueryParam('manage', 'edit-nickname')
                     } }
                     fontWeight={ 'normal' }
                     lineHeight='leading-5'
                  />
               </div>
         }
         <div className='member-options-item flex w-full h-10 items-center justify-between'>
            <Button
               textMarginX='0'
               text='Add or remove tags'
               textPosition='left'
               textSize='small'
               textColor='major'
               classNames='h-full w-full gap-3 px-4'
               iconName='tag-new'
               fontIconColor='secondary'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  actions(e, 'add_tag')
               } }
               fontWeight={ 'normal' }
               lineHeight='leading-5'
            />
         </div>
         <div className='member-options-item flex w-full h-10 items-center justify-between'>
            <Button
               textMarginX='0'
               text={ !isMuted ? 'Mute member' : 'Unmute member' }
               textPosition='left'
               textSize='small'
               textColor='major'
               classNames='h-full w-full gap-3 px-4'
               iconName={ !isMuted ? 'chat-mute' : 'chat' }
               fontIconColor='secondary'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  actions(e, isMuted ? 'unmute' : 'mute')
                  !isMuted && QueryParams.addQueryParam('manage', `mute-member`)
               } }
               fontWeight={ 'normal' }
               lineHeight='leading-5'
            />
         </div>
         {/* backend is not ready yet */}
         <div className='member-options-item flex w-full h-10 items-center justify-between'>
            <Button
               textMarginX='0'
               text={ `${ !!isDisabledNotifications ? 'Enable' : 'Disable' } all notifications from this member` }
               textSize='small'
               textPosition='left'
               textColor='major'
               classNames='h-full w-full gap-3 px-4'
               iconName={ !!isDisabledNotifications ? 'notifications' : 'off-notificartion' }
               fontIconColor='secondary'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  actions(e, !!isDisabledNotifications ? 'enable_notifications' : 'disable_notifications')
               } }
               fontWeight={ 'normal' }
               lineHeight='leading-5'
            />
         </div>
         <div className='member-options-item flex w-full h-10 items-center justify-between'>
            <Button
               textMarginX='0'
               text='Contact support about this member'
               textSize='small'
               textPosition='left'
               primaryColor
               classNames={ `h-full w-full gap-3 px-4 intercom-more-info-on-member-${ memberUsername }` }
               iconName='help'
               fontIconColor='major'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ e => {
                  e.stopPropagation()
                  e.preventDefault()
                  QueryParams.addQueryParam(`contact-about-member`, memberUsername)
                  window.Intercom('showNewMessage');
                  actions(e, 'help')
               } }
               fontWeight={ 'normal' }
               lineHeight='leading-5'
            />
         </div>

         <div
            className='member-options-item flex w-full h-10 items-center justify-between'>
            <Button
               textMarginX='0'
               text={ isBlocked ? 'Unblock this member' : 'Block this member' }
               iconName='block'
               textPosition='left'
               textSize='small'
               fontIconSize='large'
               iconPosition='left'
               classNames='h-full w-full gap-3 px-4'
               backgroundColor='transparent'
               fontIconColor='error'
               textColor='error'
               padding='none'
               alignment='end'
               onClick={ e => {
                  e.stopPropagation()
                  e.preventDefault()
                  actions(e, isBlocked ? 'unblock' : 'block')
                  !isBlocked && QueryParams.addQueryParam('manage', `block-member`)
               } }
               fontWeight={ 'normal' }
               lineHeight='leading-5'
            />
         </div>
      </div>
   )
}


MemberOptions.propTypes = {
   closeModalWithTimeout: PropTypes.func,
   viewProfile: PropTypes.bool,
   isMobile: PropTypes.bool,
   giveFreeMembership: PropTypes.func,
   memberStatus: PropTypes.string,
   insideChat: PropTypes.bool,
   openCustomTagModal: PropTypes.func,
   goToProfile: PropTypes.func,
   isBlocked: PropTypes.bool,
   isMuted: PropTypes.bool,
   id: PropTypes.number,
   setMemberOptionModal: PropTypes.func,
   goToPrivateChat: PropTypes.func,
   editUsername: PropTypes.func,
   isDisabledNotifications: PropTypes.bool,
   memberUsername: PropTypes.string,
   isLoading: PropTypes.bool,
   goToDetails: PropTypes.func,
   onOpenEditNickname: PropTypes.func,
   memberData: PropTypes.object,
}

OptionsModal.propTypes = {
   tooltipId: PropTypes.string,
   viewProfile: PropTypes.bool,
   onCloseModal: PropTypes.func,
   bottomPosition: PropTypes.string,
   giveFreeMemberShip: PropTypes.func,
   memberStatus: PropTypes.string,
   insideChat: PropTypes.bool,
   openCustomTagModal: PropTypes.func,
   goToProfile: PropTypes.func,
   isBlocked: PropTypes.bool,
   isMuted: PropTypes.bool,
   id: PropTypes.number,
   setMemberOptionModal: PropTypes.func,
   isMobile: PropTypes.bool,
   goToPrivateChat: PropTypes.func,
   editUsername: PropTypes.func,
   rightPosition: PropTypes.string,
   isDisabledNotifications: PropTypes.bool,
   optionButtonRef: PropTypes.any,
   memberUsername: PropTypes.string,
   isLoading: PropTypes.bool,
   goToDetails: PropTypes.func,
   onOpenEditNickname: PropTypes.func,
   memberData: PropTypes.object,
}

OptionsModal.defaultProps = {
   rightPosition: '23px',
}

export default OptionsModal
