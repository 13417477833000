import {
   fetchResources,
   defaultAccesses,
   getMediaDownloadLink,
   getCountStatistics,
}  from 'admin/api/AuthApi';

import {
   fetchResourcesRequest,
   fetchResourcesCompleted,
   fetchResourcesFailed,
   defaultAccessesRequest,
   defaultAccessesCompleted,
   defaultAccessesFailed,
   setDownloadButtonLoading,
   setWrongDownloadButtonModal,
   setCountsStates,
} from './actions';
import toast from "common/utils/toast";
import { downloadMediaFromUrl } from 'common/utils/utils'

export function fetchResourcesOperation(params = '') {
   return async (dispatch) => {
      dispatch(fetchResourcesRequest());
      try {
         const { data } = await fetchResources(params);
         dispatch(fetchResourcesCompleted(data));
      } catch (error) {
         dispatch(fetchResourcesFailed());
      }
   };
}
export function defaultAccessesOperation(params = []) {
   return async (dispatch) => {
      dispatch(defaultAccessesRequest());
      try {
         const { data } = await defaultAccesses(params);
         dispatch(defaultAccessesCompleted(data));
      } catch (error) {
         dispatch(defaultAccessesFailed());
      }
   };
}

export function downloadMediaOperation(id, type, otherType) {
   return async dispatch => {
      dispatch(setDownloadButtonLoading(true));
      try {
         const { userAgent } = window.navigator;
         const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
         const isIosSafari = /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent));
         if((type === 'videos' || ('video' === otherType && 'vault' === type)) && isIos && !isIosSafari) {
            dispatch(setWrongDownloadButtonModal({
               isOpen: true,
               body: 'Video downloads are currently only available in Safari.',
            }))
            dispatch(setDownloadButtonLoading(false));
         } else {
            let appQuery = '';
            if('videos' === type  && 'livestream' === otherType){
               appQuery = 'video_type=livestream';
            }
            const { data } = await getMediaDownloadLink(id, type, appQuery)
            downloadMediaFromUrl(data.link)
            dispatch(setDownloadButtonLoading(false));
         }

      } catch (error) {
         if(error.response && (error.response.data && !!error.response.data.body)){
            const { data } = error.response
            let isLimitedModal = false;
            if(429 === error.response.status) isLimitedModal = true;
            dispatch(setWrongDownloadButtonModal({
               isOpen: true,
               body: data.body,
               header: data.header,
               is_limited: isLimitedModal,
            }))
         } else {
            toast.error('Something went wrong');
         }
         dispatch(setDownloadButtonLoading(false));
      }
   }
}


export function fetchMediaCountsStateOperation(type = 'videos', qeury = []) {
   return async (dispatch) => {
      // dispatch(actions.fetchRequest(key));
      try {
         let apiName = type;
         switch (type) {
            case 'store':
               apiName = 'products';
               break;
            case 'photosets':
               apiName = 'photo-collections';
               break;
            case 'streams':
               apiName = 'videos';
               break;
            case 'orderRequests':
               apiName = 'order-requests';
               break;
   
            default:
               break;
         }
         const { data } = await getCountStatistics(apiName, qeury);
         dispatch(setCountsStates(data, type));
      } catch (error) {
         // dispatch(actions);
      }
   };
}
export function updateCountStateAfterDelete(id, type) {
   return async (dispatch, getStore) => {
      // dispatch(actions.fetchRequest(key));
      try {
         const storeData = getStore();
         let { resources: { countesState } } = storeData;
         let list = [];
         switch (type) {
            case 'videos':
               let { videos: { videosData } } = storeData;
               list = videosData?.data || [];
               break;
            case 'photosets':
               let { photosets: { photosetsData } } = storeData;
               list = photosetsData?.data || [];
               break;
            case 'streams':
               let { livestream: { recordedStreams } } = storeData;
               list = recordedStreams?.data || [];
               break;
            case 'store':
               let { store: { store } } = storeData;
               list = store?.data || [];
               break;
            case 'stories':
               let { stories: { storiesData } } = storeData;
               list = storiesData?.data || [];

               break;

            default:
               break;
         }
         if(id) {
            let data = [...list].find(i => i.id === id);
            if(data){
               let status = null;
               if('stories' === type){
                  status = 'image' === data?.type ? 'photos' : 'videos';
               } else {
                  status = 'scheduled';
                  let publishKey = 'is_published'
                  if('store'){
                     publishKey = 'active'
                  }
                  if(!data.is_future_publish_enabled) {
                     status = !data[publishKey] ?  'unpublished' : 'published'
                  }
               }
               const countState = countesState?.[type]  || {}
               let total = countState?.total || 0;
               if(0 < total) total = --total;
               let newCountState = {
                  ...countState,
                  total,

               }
               if(status){
                  let count = countState?.[status] || 0;
                  if(0 < count) count = --count
                  newCountState = {
                     ...newCountState,
                     [status]: count,
                  }
               }
               dispatch(setCountsStates(newCountState, type));
            }
         }
      } catch (error) {
         // dispatch(actions);
      }
   };
}

