import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'common/components/elements/icons';
import Radio from 'common/components/elements/inputs/radio-label';
import Checkbox from 'common/components/elements/inputs/checkbox-label';
import Modal from 'common/components/modules/modal';
import Search from 'common/components/elements/inputs/search';
import Button from 'common/components/elements/buttons/primary';
import EmptyState from 'common/components/modules/empty-states';
import Loader from 'common/components/elements/loader/circle';
import TruncateText from 'common/components/elements/truncate-text';
import './style.scss'
import IconLabel from 'common/components/elements/icon-label';
import CircleLoader from 'common/components/elements/loader/circle';
import Avatar from 'common/components/modules/avatar';
import Flag from 'common/components/elements/flags';

const FilterContent = ({
   data,
   desktopType,
   isMultiplSelect,
   isSearchable,
   activeSelectedValue,
   onScrollContent,
   filterNameKey,
   onCloseContent,
   isTopSeparate,
   onFilterChange,
   onClickShowResult,
   onSearchChange,
   searchPlaceholder,
   isLoadinngData,
   primaryIcon,
   filterValueKey,
   style,
   onClickClear,
   isSort,
   children,
   animate,
   isDisabled,
   isAutoSave,
   onAutoFilter,
   onCloseDkContent,
   contentStyles,
   version,
   isEmpty,
   showNewRadioStyles,
   viewClassName,
   viewWidth,
   hasClearButton,
   contentMarginToLeftIcon,
   valueColor,
   contentCardsStyle,
   hasCloseButton,
   hasFilterButtonContent,
   checkboxLabelClassname,
   filterDescription,
   radioClassName,
   contentClassName,
   checkboxSize,
   showCloseButton,
   showClearButton,
   disabledState,
   useIconLabel,
   showSelectAll,
   isSelectedAll,
   disabledOptionTooltiptext,
   classNamesToRemove,
   isLoadingNextPageData,
   loaderClassName,
   contentId,
   useMemberAvatar,
   notShowTooltip,
   notTruncate,
   searchValue,
   checkboxClassName,
   truncateTextSize,
   checkboxMargin,
   useCountryFlag,
   contentRef,
   showCloseButtonInsteadOfClear,
   scrollToCheckedElement,
}) => {

   const view =  <Content
      data={ data }
      isMultiplSelect={ isMultiplSelect }
      isSearchable={ isSearchable }
      onScrollContent={ onScrollContent }
      filterNameKey={ filterNameKey }
      isTopSeparate={ isTopSeparate }
      activeValue={ activeSelectedValue }
      onFilterChange={ onFilterChange }
      onClickShowResult={ onClickShowResult }
      onSearchChange={ onSearchChange }
      searchPlaceholder={ searchPlaceholder }
      isLoadinngData={ isLoadinngData }
      primaryIcon={ primaryIcon }
      filterValueKey={ filterValueKey }
      onClickClear={ onClickClear }
      isSort={ isSort }
      children={ children }
      isDisabled={ isDisabled }
      isAutoSave={ isAutoSave }
      onAutoFilter={ onAutoFilter }
      onCloseDkContent={ onCloseDkContent }
      isMobile={ desktopType === 'mobile' }
      version={ version }
      isEmpty={ isEmpty }
      showNewRadioStyles={ showNewRadioStyles }
      viewClassName={ viewClassName }
      viewWidth={ viewWidth }
      hasClearButton={ hasClearButton }
      contentMarginToLeftIcon={ contentMarginToLeftIcon }
      valueColor={ valueColor }
      contentCardsStyle={ contentCardsStyle }
      hasCloseButton={ hasCloseButton }
      hasFilterButtonContent={ hasFilterButtonContent }
      checkboxLabelClassname={ checkboxLabelClassname }
      filterDescription={ filterDescription }
      radioClassName={ radioClassName }
      contentClassName={ contentClassName }
      checkboxSize={ checkboxSize }
      showCloseButton={ showCloseButton }
      showClearButton={ showClearButton }
      disabledState={ disabledState }
      useIconLabel={ useIconLabel }
      showSelectAll={ showSelectAll }
      isSelectedAll={ isSelectedAll }
      disabledOptionTooltiptext={ disabledOptionTooltiptext }
      classNamesToRemove={ classNamesToRemove }
      isLoadingNextPageData={ isLoadingNextPageData }
      loaderClassName={ loaderClassName }
      contentId={ contentId }
      useMemberAvatar={ useMemberAvatar }
      notShowTooltip={ notShowTooltip }
      notTruncate={ notTruncate }
      searchValue={ searchValue }
      checkboxClassName={ checkboxClassName }
      truncateTextSize={ truncateTextSize }
      checkboxMargin={ checkboxMargin }
      useCountryFlag={ useCountryFlag }
      contentRef={ contentRef }
      showCloseButtonInsteadOfClear={ showCloseButtonInsteadOfClear }
      scrollToCheckedElement={ scrollToCheckedElement }
   />
   if(desktopType === 'mobile'){
      return (
         <Modal
            onClose={ onCloseContent }
            roundedModal='lg'
            animate={ animate }
         >
            {view}
         </Modal>
      );

   } else {
      return (
         <div
            style={ {
               ...style,
               ...contentStyles,
               zIndex: '100 !important',
            } }
            className='absolute desktop-filter-content shadow dark:shadow-dark  dark:bg-panel-dark dark:text-major-dark dark:border-divider-dark border-divider'
            id='desktop-filter-content'
         >
            {view}
         </div>
      )
   }

};

const Content = ({
   data,
   isMultiplSelect,
   onScrollContent,
   filterNameKey,
   isTopSeparate,
   activeValue,
   onFilterChange,
   isSearchable,
   onClickShowResult,
   onSearchChange,
   searchPlaceholder,
   isLoadinngData,
   primaryIcon,
   filterValueKey,
   onClickClear,
   isSort,
   children,
   isDisabled,
   isAutoSave,
   onAutoFilter,
   onCloseDkContent,
   isMobile,
   version,
   isEmpty,
   showNewRadioStyles,
   viewClassName,
   viewWidth,
   hasClearButton,
   contentMarginToLeftIcon,
   valueColor,
   contentCardsStyle,
   hasCloseButton,
   hasFilterButtonContent,
   checkboxLabelClassname,
   filterDescription,
   radioClassName,
   contentClassName,
   checkboxSize,
   showCloseButton,
   showClearButton,
   disabledState,
   useIconLabel,
   showSelectAll,
   isSelectedAll,
   disabledOptionTooltiptext,
   classNamesToRemove,
   isLoadingNextPageData,
   loaderClassName,
   contentId,
   useMemberAvatar,
   notShowTooltip,
   notTruncate,
   searchValue,
   checkboxClassName,
   truncateTextSize,
   checkboxMargin,
   useCountryFlag,
   contentRef,
   showCloseButtonInsteadOfClear,
   scrollToCheckedElement,
}) => {
   const [serachValue, setSearchValue] = useState('')

   useEffect(() => {
      setSearchValue(searchValue)
   }, [searchValue])

   const scrollToCheckedItem = () => {
      const element = document.getElementById('checked-item');
      const conteiner = document.getElementById('desktop-filter-content');
      if(element && conteiner) {
         const elementRect = element.getBoundingClientRect();
         const containerRect = conteiner.getBoundingClientRect();

         const offsetTop = elementRect.top - containerRect.top - 4;
         setTimeout(() => {
            conteiner.scrollTo({
               top: offsetTop,
               behavior: 'smooth',
            });
         }, 100);
      }
   };

   useEffect(() => {
      if(scrollToCheckedElement) {
         scrollToCheckedItem()
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [scrollToCheckedElement])

   return (
      <div className='select-none'>
         {
            children ? (
               children
            ) : (
               <>
                  {
                     isSearchable && (
                        <>
                           <div className='h-12 mx-1 mt-1 px-1 filter-search-content'>
                              <Search
                                 onChange={ (value) => {
                                    setSearchValue(value);
                                    onSearchChange(value)
                                 } }
                                 placeholder={ searchPlaceholder }
                                 value={ searchValue }
                                 isDebounce
                                 className='!px-4 gap-1'
                              />
                           </div>
                           <div className='w-full border-b dark:border-divider-dark border-divider filter-search-divider' />
                        </>
                     )
                  }
                  <div
                     onScroll={ onScrollContent ?  (e) => onScrollContent(e) : null }
                     id={ contentId }
                     className={ cx({
                        'flex flex-col overflow-y-auto resposnive-filter py-1': true,
                        'resposnive-filter-content-simpl': isLoadinngData,
                        'resposnive-filter-content': !classNamesToRemove?.includes('resposnive-filter-content') && isSearchable,
                        'py-1': 'v2' === version,
                        'p-0': 'v1' === version,
                        'ams-custom-scrool': !isMobile,
                        '!px-0 !py-1': !!contentCardsStyle && contentCardsStyle?.forContent,
                        [`${ contentClassName }`]: !!contentClassName,
                     }) }
                     ref={ contentRef }
                  >
                     {
                        !isLoadinngData && data?.length === 0 && (
                           <div className='p-2'>
                              <EmptyState type={ 'search' }
                                 result={ serachValue } />
                           </div>
                        )
                     }
                     {
                        isLoadinngData && (
                           <div className='h-full w-full flex items-center justify-center min-h-24'>
                              <Loader />
                           </div>
                        )
                     }
                     {
                        filterDescription !== '' && <div className='py-[10px] px-2'>
                           <span className='font-normal text-sm text-secondary dark:text-secondary-dark '>{filterDescription}</span>
                        </div>
                     }
                     {
                        isMultiplSelect && showSelectAll &&
                           <Checkbox
                              name={ `select_all_filter_item_${ contentId }` }
                              onChange={ () => {
                                 onFilterChange('select_all', 'Select all')
                              } }
                              checked={ isSelectedAll }
                              position='right'
                              className={ cx({
                                 'border-b dark:border-divider-dark border-divider': isTopSeparate,
                                 'hover:bg-hover dark:hover:bg-hover-dark text-sm': true,
                                 'checkbox_v2': 'v2' === version,
                              }) }
                              labelClassName={ checkboxLabelClassname }
                              padding={ !!contentCardsStyle && contentCardsStyle?.forContent ? '[10px]' : 'v2' === version ? ' py-2 px-[14px]' : '3' }
                              checkboxSize={ checkboxSize }
                           >
                              <>
                                 <TruncateText
                                    textClass={ cx({
                                       // 'pr-2': useIconLabel,
                                    }) }
                                    className={ cx({
                                       'w-full flex justify-end items-center text-major dark:text-major-dark': true,
                                    }) }
                                    text={ 'Select all' }
                                    textSize={ `${ !!contentClassName ? '14px' : '16px' }` }
                                    fontWeight={ 'v1' === version ? '500' : '400' }
                                    width='calc(100% - 24px)'
                                 />
                              </>
                           </Checkbox>
                     }
                     {
                        !isLoadinngData &&  data.map((value, index) => {
                           let isSelected = false;
                           let activeData = activeValue.value
                           if(isMultiplSelect && Array.isArray(activeData)) {
                              isSelected = activeData.includes(value[filterValueKey])
                           }
                           let icon = primaryIcon ? primaryIcon : value.icon

                           let isDisabledOption = disabledState.includes(value[filterValueKey])

                           const view = (
                              <div
                                 className={ cx({
                                    [`flex w-4/5 ${ viewClassName }`]: true,
                                    [`gap-2`]: useMemberAvatar,
                                 }) }
                              >
                                 {
                                    useIconLabel &&
                                    <IconLabel
                                       className='w-6 h-6 min-w-6 min-h-6'
                                       name={ value?.iconProps?.iconName }
                                       color={ value?.iconProps?.color }
                                       size='base'
                                    />
                                 }
                                 {
                                    useMemberAvatar &&
                                       <Avatar
                                          avatarSrc={ value?.avatar }
                                          size='24px'
                                          username={ value?.name }
                                          className={ `min-h-[24px] min-w-[24px]` }
                                       />
                                 }
                                 {
                                    icon && (
                                       <div
                                          style={ { minWidth: '24px' } }
                                          className='h-6 w-6 flex items-center justify-center'>
                                          <Icon name={ icon } size='lg' color={ isDisabledOption ? 'disabled' : 'secondary' } />
                                       </div>
                                    )
                                 }
                                 {
                                    useCountryFlag &&
                                    <Flag
                                       country={ value[filterValueKey] }
                                       className='!px-0 flex justify-center items-center mr-3'
                                       width='20px'
                                       height='20px'
                                    />
                                 }
                                 {
                                    value && value.status && !!Object.keys(value.status).length ? (
                                       <p
                                          className={ cx(
                                             'flex items-center justify-center w-18 h-6 text-major-dark rounded-full text-xs mr-5 item-status-min-width',
                                             value.status.color
                                          ) }
                                       >
                                          {value.status.name}
                                       </p>
                                    ) : ''
                                 }
                                 <>
                                    <TruncateText
                                       textClass={ cx({
                                          'leading-tight dark:text-major-dark': isTopSeparate && index !== data.length - 1,
                                          [`${ valueColor } dark:text-major-dark`]: 'v2' === version,
                                          '!font-medium text-major dark:text-major-dark': 'v1' === version,
                                          [`ml-${ contentMarginToLeftIcon }`]: icon,
                                          '!font-normal !text-base': !!contentCardsStyle && contentCardsStyle?.forContent,
                                          '!text-disabled': isDisabledOption,
                                          'pl-2': useIconLabel,
                                       }) }
                                       className={ cx({
                                          'w-full': true,
                                          'items-center': isTopSeparate && index !== data.length - 1,
                                       }) }
                                       text={ value[filterNameKey] }
                                       textSize={ truncateTextSize }
                                       fontWeight={ 'v1' === version ? '500' : '400' }
                                       width={ notTruncate ? 'full' : 'calc(100% - 24px)' }
                                       notShowTooltip={ notShowTooltip }
                                       innerTextAsHTML={ value?.innerTextAsHTML }
                                    />
                                    {
                                       value.count && <span className='text-placeholder dark:text-placeholder-dark'>&nbsp; • {value.count}</span>
                                    }
                                    {
                                       value?.price && <span className='text-secondary dark:text-secondary-dark  text-xs'>
                                          {value?.price}
                                       </span>
                                    }
                                 </>
                              </div>
                           )
                           if(!!isMultiplSelect) {
                              return (
                                 <Checkbox
                                    name={ `${ value[filterValueKey] }_filter` }
                                    onChange={ () => {
                                       if(isAutoSave && !isMobile) {
                                          onAutoFilter(value[filterValueKey], value[filterNameKey])
                                       } else {
                                          onFilterChange(value[filterValueKey], value[filterNameKey])
                                       }
                                    } }
                                    checked={ isSelected }
                                    position='right'
                                    key={ value.id ? value.id : index.toString() }
                                    className={ cx({
                                       'border-b dark:border-divider-dark border-divider': isTopSeparate && index !== data.length - 1,
                                       'hover:bg-hover dark:hover:bg-hover-dark': true,
                                       'checkbox_v2': 'v2' === version,
                                       [`${ checkboxClassName }`]: Boolean(checkboxClassName),
                                       'intercom-my-favorites-member': value[filterValueKey] === 'favorite',
                                    }) }
                                    labelClassName={ checkboxLabelClassname }
                                    padding={ !!contentCardsStyle && contentCardsStyle?.forContent ? '[10px]' : 'v2' === version ? ' py-2 px-[14px]' : '3' }
                                    checkboxSize={ checkboxSize }
                                    disabled={ isDisabledOption }
                                    margin={ checkboxMargin }
                                    isSelectedState
                                    disabledOptionTooltiptext={ isDisabledOption ? disabledOptionTooltiptext : '' }
                                 >
                                    {view}
                                 </Checkbox>
                              )
                           } else {
                              return (
                                 <div
                                    id={ activeData && (activeData === value[filterValueKey] || activeValue[filterValueKey] === value[filterValueKey])  ? 'checked-item' : '' }
                                 >
                                    <Radio
                                       id={ `${ value[filterValueKey] }_filter` }
                                       onChange={ () => onFilterChange(value[filterValueKey], value[filterNameKey]) }
                                       checked={ activeData && (activeData === value[filterValueKey] || activeValue[filterValueKey] === value[filterValueKey]) }
                                       key={ value.id ? value.id : index.toString() }
                                       className={ cx({
                                          'border-b dark:border-divider-dark border-divider': isTopSeparate && index !== data.length - 1,
                                          'hover:bg-hover dark:hover:bg-hover-dark': !isDisabledOption,
                                          [`${ radioClassName }`]: !!radioClassName,
                                       }) }
                                       paddingY={ !!contentCardsStyle && contentCardsStyle?.forContent ? '[10px]' : 'v2' === version ? ' py-2 px-[14px]' : '3' }
                                       disabled={ isDisabledOption }
                                       isSelectedState
                                       disabledOptionTooltiptext={ isDisabledOption ? disabledOptionTooltiptext : '' }
                                    >
                                       {view}
                                    </Radio>
                                 </div>
                              )
                           }
                        })
                     }
                     {
                        isLoadingNextPageData &&
                        <div className={ `flex items-center justify-center ${ loaderClassName }` }>
                           <CircleLoader color='blue' />
                        </div>
                     }
                  </div>
               </>
            )
         }
         {
            !isMultiplSelect && isAutoSave && !!activeValue.value && (activeValue.value.length > 0 || activeValue.name?.length > 0) && hasClearButton && (
               <div
                  className={ cx({
                     'flex bg-panel dark:bg-panel-dark rounded-b-lg items-center justify-between filter-button-content': true,
                     'border-t dark:border-divider-dark border-divider p-button-content h-14': !isMultiplSelect,
                  }) }
               >
                  <div className='h-9 clear-button'>
                     <Button
                        text='Clear'
                        textColor='secondary dark:text-secondary-dark '
                        backgroundColor='transparent'
                        textSize='base'
                        onClick={ onClickClear }
                        classNames='h-full flex px-2 items-center'
                        padding='none'
                        iconName='cancel'
                        fontIconColor='secondary'
                        darkIconColor='secondary-dark'
                        fontIconSize='large'
                        iconPosition='left'
                     />
                  </div>
               </div>
            )
         }
         {
            !isEmpty && (isMobile || (!isSort && isMultiplSelect)) && hasFilterButtonContent && (
               <div
                  className={ cx({
                     'flex bg-panel dark:bg-panel-dark rounded-b-lg items-center justify-between filter-button-content': true,
                     'border-t dark:border-divider-dark border-divider p-button-content h-14': !!isMultiplSelect,
                  }) }
               >
                  {
                     showClearButton && (
                        isMobile && hasClearButton ?
                           (
                              <div className='h-9 clear-button'>
                                 <Button
                                    text='Clear'
                                    textColor='secondary dark:text-secondary-dark '
                                    backgroundColor='transparent'
                                    textSize='base'
                                    onClick={ onClickClear }
                                    classNames='h-full flex px-2 items-center dark:text-major-dark'
                                    padding='none'
                                    iconName='cancel'
                                    fontIconColor='text-secondary'
                                    fontIconSize='large'
                                    iconPosition='left'
                                 />
                              </div>
                           )
                           : (!!activeValue.value && activeValue.value.length > 0) &&
                           (
                              <div className='h-9 clear-button'>
                                 <Button
                                    text={ showCloseButton ? 'Close' : 'Clear' }
                                    textColor='secondary dark:text-secondary-dark '
                                    backgroundColor='transparent'
                                    textSize='small'
                                    onClick={ onClickClear }
                                    classNames='h-full flex px-2 items-center'
                                    padding='none'
                                    iconName={ showCloseButton ? '' : 'cancel' }
                                    fontIconColor='secondary'
                                    darkIconColor='secondary-dark'
                                    fontIconSize='large'
                                    iconPosition='left'
                                 />
                              </div>
                           )
                     )
                  }
                  {
                     isAutoSave && !isMobile && hasCloseButton && (
                        <div className='h-9 clear-button ml-auto'>
                           <Button
                              text='Close'
                              textColor='secondary dark:text-secondary-dark '
                              backgroundColor='transparent'
                              textSize='small'
                              onClick={ () => onCloseDkContent() }
                              classNames='h-full flex px-2 items-center dark:text-major-dark'
                              padding='none'
                           />
                        </div>
                     )
                  }
                  {
                     showCloseButtonInsteadOfClear && !isMobile && hasCloseButton && !activeValue?.value?.length && (
                        <div className='h-9 clear-button '>
                           <Button
                              text='Close'
                              textColor='secondary dark:text-secondary-dark '
                              backgroundColor='transparent'
                              textSize='small'
                              onClick={ () => onCloseDkContent() }
                              classNames='h-full flex px-2 items-center dark:text-major-dark'
                              padding='none'
                           />
                        </div>
                     )
                  }
                  {
                     isMobile && isAutoSave && (
                        <div className='h-9 ml-auto'>
                           <Button
                              text='Apply'
                              primaryColor
                              textSize='base'
                              onClick={ onClickShowResult }
                              padding='none'
                              disabled={ isDisabled }
                              borderRadius='large'
                              classNames='px-3 h-full'
                           />
                        </div>
                     )
                  }
                  {
                     isMultiplSelect && !isAutoSave && (
                        <div className='h-9 filter-show-result ml-auto'>
                           <Button
                              text={ `${ isMobile ? 'Apply' : `Show results ${ activeValue.name ? `(${ activeValue.name })` : '' }` }` }
                              primaryColor
                              textSize='small'
                              onClick={ onClickShowResult }
                              padding='none'
                              disabled={ isDisabled }
                              borderRadius='large'
                              classNames='px-3 h-full'
                           />
                        </div>
                     )
                  }
               </div>
            )
         }
      </div>
   )
}

FilterContent.defaultProps = {
   desktopType: 'mobile',
   onFilterChange: (...arg) => console.log(...arg),
   onClickShowResult: () => {},
   onClickClear: () => {},
   onSearchChange: () => {},
   searchPlaceholder: 'Search',
   isLoadinngData: false,
   style: {},
   isAutoSave: true,
   onAutoFilter: () => {},
   contentStyles: {},
   hasClearButton: true,
   valueColor: 'text-major',
   contentCardsStyle: {},
   viewClassName: '',
   hasCloseButton: true,
   hasFilterButtonContent: true,
   checkboxLabelClassname: '',
   filterDescription: '',
   contentClassName: '',
   checkboxSize: '6',
   showCloseButton: false,
   showClearButton: true,
   disabledState: [],
   disabledOptionTooltiptext: 'This view cannot be reordered',
   notShowTooltip: false,
   notTruncate: false,
   useCountryFlag: false,
   showCloseButtonInsteadOfClear: false,
};

FilterContent.propTypes = {
   data: PropTypes.array.isRequired,
   desktopType: PropTypes.string,
   filterNameKey: PropTypes.string,
   isTopSeparate: PropTypes.bool,
   onFilterChange: PropTypes.func,
   onClickShowResult: PropTypes.func,
   onSearchChange: PropTypes.func,
   searchPlaceholder: PropTypes.string,
   isLoadinngData: PropTypes.bool,
   primaryIcon: PropTypes.string,
   style: PropTypes.object,
   isSort: PropTypes.bool,
   onClickClear: PropTypes.func,
   isDisabled: PropTypes.bool,
   children: PropTypes.any,
   isAutoSave: PropTypes.bool,
   onAutoFilter: PropTypes.func,
   onCloseDkContent: PropTypes.func,
   contentStyles: PropTypes.object,
   isMultiplSelect: PropTypes.bool,
   isSearchable: PropTypes.bool,
   activeSelectedValue: PropTypes.any,
   onScrollContent: PropTypes.func,
   onCloseContent: PropTypes.func,
   animate: PropTypes.any,
   filterValueKey: PropTypes.any,
   version: PropTypes.string,
   isEmpty: PropTypes.bool,
   showNewRadioStyles: PropTypes.bool,
   viewClassName: PropTypes.string,
   viewWidth: PropTypes.string,
   contentMarginToLeftIcon: PropTypes.number,
   hasClearButton: PropTypes.bool,
   valueColor: PropTypes.string,
   contentCardsStyle: PropTypes.object,
   hasCloseButton: PropTypes.bool,
   hasFilterButtonContent: PropTypes.bool,
   checkboxLabelClassname: PropTypes.string,
   filterDescription: PropTypes.string,
   radioClassName: PropTypes.string,
   contentClassName: PropTypes.string,
   checkboxSize: PropTypes.string,
   showCloseButton: PropTypes.bool,
   showClearButton: PropTypes.bool,
   disabledState: PropTypes.array,
   useIconLabel: PropTypes.bool,
   showSelectAll: PropTypes.bool,
   isSelectedAll: PropTypes.bool,
   disabledOptionTooltiptext: PropTypes.string,
   classNamesToRemove: PropTypes.array,
   isLoadingNextPageData: PropTypes.bool,
   contentId: PropTypes.string,
   useMemberAvatar: PropTypes.bool,
   loaderClassName: PropTypes.string,
   notShowTooltip: PropTypes.bool,
   notTruncate: PropTypes.bool,
   searchValue: PropTypes.string,
   checkboxClassName: PropTypes.string,
   truncateTextSize: PropTypes.string,
   checkboxMargin: PropTypes.string,
   useCountryFlag: PropTypes.bool,
   contentRef: PropTypes.any,
   showCloseButtonInsteadOfClear: PropTypes.bool,
   scrollToCheckedElement: PropTypes.bool,
};
Content.propTypes = {
   data: PropTypes.array.isRequired,
   isMultiplSelect: PropTypes.bool,
   onScrollContent: PropTypes.func,
   filterNameKey: PropTypes.string,
   isTopSeparate: PropTypes.bool,
   isSearchable: PropTypes.bool,
   activeValue: PropTypes.any,
   onFilterChange: PropTypes.func,
   onClickShowResult: PropTypes.func,
   onSearchChange: PropTypes.func,
   searchPlaceholder: PropTypes.string,
   isLoadinngData: PropTypes.bool,
   primaryIcon: PropTypes.string,
   filterValueKey: PropTypes.string,
   isSort: PropTypes.bool,
   onClickClear: PropTypes.func,
   isDisabled: PropTypes.bool,
   children: PropTypes.any,
   isAutoSave: PropTypes.bool,
   onAutoFilter: PropTypes.func,
   onCloseDkContent: PropTypes.func,
   isMobile: PropTypes.bool,
   version: PropTypes.string,
   isEmpty: PropTypes.bool,
   showNewRadioStyles: PropTypes.bool,
   viewClassName: PropTypes.string,
   viewWidth: PropTypes.string,
   contentMarginToLeftIcon: PropTypes.number,
   hasClearButton: PropTypes.bool,
   valueColor: PropTypes.string,
   contentCardsStyle: PropTypes.object,
   hasCloseButton: PropTypes.bool,
   hasFilterButtonContent: PropTypes.bool,
   checkboxLabelClassname: PropTypes.string,
   filterDescription: PropTypes.string,
   radioClassName: PropTypes.string,
   contentClassName: PropTypes.string,
   checkboxSize: PropTypes.string,
   showCloseButton: PropTypes.bool,
   showClearButton: PropTypes.bool,
   disabledState: PropTypes.array,
   useIconLabel: PropTypes.bool,
   showSelectAll: PropTypes.bool,
   isSelectedAll: PropTypes.bool,
   disabledOptionTooltiptext: PropTypes.string,
   classNamesToRemove: PropTypes.array,
   isLoadingNextPageData: PropTypes.bool,
   contentId: PropTypes.string,
   useMemberAvatar: PropTypes.bool,
   loaderClassName: PropTypes.string,
   notShowTooltip: PropTypes.bool,
   notTruncate: PropTypes.bool,
   searchValue: PropTypes.string,
   checkboxClassName: PropTypes.string,
   truncateTextSize: PropTypes.string,
   checkboxMargin: PropTypes.string,
   useCountryFlag: PropTypes.bool,
   contentRef: PropTypes.any,
   showCloseButtonInsteadOfClear: PropTypes.bool,
   scrollToCheckedElement: PropTypes.bool,
};

export default FilterContent;
